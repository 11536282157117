import { useContext } from 'react';
import cn from 'classnames';

import createStyles from '@guestyci/foundation/createStyles';
import TextField from '@guestyci/foundation/TextField';
import Radio from '@guestyci/foundation/Radio';
import { Col, Row } from '@guestyci/foundation/Layout';
import t from '@guestyci/localize/t.macro';
import useFeatureToggle from '@guestyci/feature-toggle-fe/useFeatureToggle';

import { WebsiteSettingsContext } from 'context/WebsiteSettingsContext';
import CancellationPolicyText from 'constants/cancellationPolicy';
import CancellationPolicyKeys from 'constants/cancellationPolicyKeys';
import { NEW_CANCELLATION_POLICY_UI } from 'constants/featureToggleNames';
import { DEFAULT_RATE_PLAN_ID } from 'constants/constants';

import CancellationPolicy from 'components/CancellationPolicy';
import PriceConverter from 'components/PriceConverter';

const useStyles = createStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  ratePlanItem: {
    margin: '5px 0px',
    width: '100%',
    border: '1px solid #D4D8E1',
    cursor: 'pointer',
    position: 'relative',
    padding: 10,
    '&[data-chosen="true"]': {
      border: `1px solid`,
      borderColor: ({ colors }) => colors?.mainButtonsAndLinks || '#1BBCEF',
      background: ({ colors }) => colors?.mainButtonsAndLinks || '#1BBCEF',
      color: '#fff',
      '& div': {
        color: '#fff',
      },
    },
  },
  radioButton: {
    '& button': {
      marginRight: 10,
    },
  },
  ratePlanName: {
    padding: 10,
  },
  price: {
    textAlign: 'right',
  },
}));
const CancellationPolicyWithRadioRender = ({ ratePlan, selectedPlan, onChange, defaultCheckInTime }) => {
  const [, isNewCancellationPolicy] = useFeatureToggle(NEW_CANCELLATION_POLICY_UI);
  const {
    contentConfiguration: { colorTheme },
  } = useContext(WebsiteSettingsContext);
  const { ratePlanItem, radioButton, price } = useStyles(colorTheme);
  const {
    name,
    _id: value,
    mealPlans,
    money: { subTotalPrice, currency },
    cancellationFee,
    cancellationPolicy,
  } = ratePlan;
  const rules = mealPlans.length ? [`${mealPlans.join(', ').replaceAll('_', ' ')} included`] : [];

  const getRefundTitle = () => {
    const policyText = CancellationPolicyText()[cancellationPolicy];
    if (cancellationPolicy === CancellationPolicyKeys.FREE) {
      return t('Free cancellation <b>{policyText}</b>', {
        policyText,
        b: (chunks) => chunks,
      });
    }
    if (cancellationPolicy === CancellationPolicyKeys.NON_REFUNDABLE) {
      return t('No refund upon cancellation <b>{policyText}</b>', {
        policyText,
        b: (chunks) => chunks,
      });
    }
    switch (cancellationFee) {
      case 0:
        return t('First night fee  <b>{policyText}</b>', {
          policyText,
          b: (chunks) => chunks,
        });
      case 100:
        return t('No refund upon cancellation <b>{policyText}</b>', {
          policyText,
          b: (chunks) => chunks,
        });
      default:
        return t('<t>{cancellationFee}</t>% non-refundable <b>{policyText}</b>', {
          policyText,
          cancellationFee,
          b: (chunks) => chunks,
          t: (chunks) => chunks,
        });
    }
  };

  const renderRules = (rules.length || cancellationPolicy) && (
    <ul>
      {cancellationFee !== '' && (
        <li>
          <TextField color="secondary">{getRefundTitle()}</TextField>
        </li>
      )}
      {rules.length > 0 &&
        rules.map(
          (rule) =>
            rule && (
              <li key={rule}>
                <TextField color="secondary">{rule}</TextField>
              </li>
            )
        )}
    </ul>
  );

  return isNewCancellationPolicy ? (
    <Row align="center" data-qa="rate-plan-card">
      <Radio
        className={cn(radioButton, 'radio')}
        value={value}
        id={name}
        onChange={onChange}
        checked={selectedPlan === value}
      />
      <Col className={ratePlanItem} data-chosen={selectedPlan === value}>
        <CancellationPolicy ratePlan={ratePlan} defaultCheckInTime={defaultCheckInTime} />
        <TextField variant="h5" bold className={price}>
          <PriceConverter amount={subTotalPrice} currency={currency} />
        </TextField>
      </Col>
    </Row>
  ) : (
    <Row align="center" data-qa="rate-plan-card">
      <Radio
        className={cn(radioButton, 'radio')}
        value={value}
        id={name}
        onChange={onChange}
        checked={selectedPlan === value}
      />
      <Col className={ratePlanItem} data-chosen={selectedPlan === value}>
        {name}
        {renderRules}
        <TextField variant="h5" bold className={price}>
          <PriceConverter amount={subTotalPrice} currency={currency} />
        </TextField>
      </Col>
    </Row>
  );
};

const CancellationPolicyWithoutRadioRender = ({ ratePlan, defaultCheckInTime }) => {
  const [, isNewCancellationPolicy] = useFeatureToggle(NEW_CANCELLATION_POLICY_UI);
  const {
    contentConfiguration: { colorTheme },
  } = useContext(WebsiteSettingsContext);
  const { ratePlanName } = useStyles(colorTheme);
  const { name, mealPlans, cancellationFee, cancellationPolicy } = ratePlan;
  const rules = mealPlans.length ? [`${mealPlans.join(', ').replaceAll('_', ' ')} included`] : [];

  const isStandardPlanTitleHidden =
    ratePlan?._id === DEFAULT_RATE_PLAN_ID && ratePlan?.cancellationPolicy == null && ratePlan?.cancellationFee == null;

  const getRefundTitle = () => {
    const policyText = CancellationPolicyText()[cancellationPolicy];
    if (cancellationPolicy === CancellationPolicyKeys.FREE) {
      return t('Free cancellation <b>{policyText}</b>', {
        policyText,
        b: (chunks) => chunks,
      });
    }
    if (cancellationPolicy === CancellationPolicyKeys.NON_REFUNDABLE) {
      return t('No refund upon cancellation <b>{policyText}</b>', {
        policyText,
        b: (chunks) => chunks,
      });
    }
    switch (cancellationFee) {
      case 0:
        return t('First night fee  <b>{policyText}</b>', {
          policyText,
          b: (chunks) => chunks,
        });
      case 100:
        return t('No refund upon cancellation <b>{policyText}</b>', {
          policyText,
          b: (chunks) => chunks,
        });
      default:
        return t('<t>{cancellationFee}</t>% non-refundable <b>{policyText}</b>', {
          policyText,
          cancellationFee,
          b: (chunks) => chunks,
          t: (chunks) => chunks,
        });
    }
  };

  const renderRules = (rules.length || cancellationPolicy) && (
    <ul>
      {cancellationFee !== '' && (
        <li>
          <TextField color="secondary">{getRefundTitle()}</TextField>
        </li>
      )}
      {rules.length > 0 &&
        rules.map(
          (rule) =>
            rule && (
              <li key={rule}>
                <TextField color="secondary">{rule}</TextField>
              </li>
            )
        )}
    </ul>
  );

  return isNewCancellationPolicy ? (
    <Col>
      <CancellationPolicy ratePlan={ratePlan} defaultCheckInTime={defaultCheckInTime} />
    </Col>
  ) : (
    <Col>
      {isStandardPlanTitleHidden ? null : <TextField className={ratePlanName}>{name}</TextField>}
      {renderRules}
    </Col>
  );
};

const RatePlan = ({ ratePlan, withRadio, selectedPlan, onChange, defaultCheckInTime }) => {
  if (!ratePlan) return null;

  return (
    <>
      {withRadio ? (
        <CancellationPolicyWithRadioRender
          ratePlan={ratePlan}
          selectedPlan={selectedPlan}
          onChange={onChange}
          defaultCheckInTime={defaultCheckInTime}
        />
      ) : (
        <CancellationPolicyWithoutRadioRender ratePlan={ratePlan} defaultCheckInTime={defaultCheckInTime} />
      )}
    </>
  );
};

export default RatePlan;
