import ReactGA from 'react-ga4';
import { useHistory } from 'react-router-dom';

const DEFAULT_OPTIONS = {
  testMode: process.env.NODE_ENV === 'development',
};
const useGoogleAnalytics4 = (googleAnalyticsKey) => {
  const history = useHistory();

  const sendPageView = (location = window.location) => {
    ReactGA.send({ hitType: 'pageview', page: `${location.pathname}${location.search}` });
  };

  const initAnalytics = () => {
    if (!googleAnalyticsKey) return;
    ReactGA.initialize(googleAnalyticsKey, DEFAULT_OPTIONS);
    sendPageView();
    history.listen(sendPageView);
  };

  const sendEvent = ({ category, action, label }) => {
    ReactGA.event({
      category,
      action,
      label,
    });
  };

  return {
    initAnalytics,
    sendEvent,
    sendPageView,
  };
};

export default useGoogleAnalytics4;
