import ReactGA from 'react-ga';
import { useHistory } from 'react-router-dom';

const DEFAULT_OPTIONS = {
  debug: process.env.NODE_ENV === 'development',
};
const useGoogleAnalyticsLegacy = (googleAnalyticsKey) => {
  const history = useHistory();

  const sendPageView = (location = window.location) => {
    ReactGA.pageview(`${location.pathname}${location.search}`, DEFAULT_OPTIONS);
  };

  const initAnalytics = () => {
    if (!googleAnalyticsKey) return;
    ReactGA.initialize(googleAnalyticsKey, DEFAULT_OPTIONS);
    sendPageView();
    history.listen(sendPageView);
  };

  const sendEvent = ({ category, action, label }) => {
    ReactGA.event({
      category,
      action,
      label,
    });
  };

  return {
    initAnalytics,
    sendEvent,
    sendPageView,
  };
};

export default useGoogleAnalyticsLegacy;
