import TagManager from 'react-gtm-module';

const gtmDataLayerName = 'dataLayerGtm';

class GTAG {
  constructor() {
    this.tagmanager = TagManager;
    this.isInitialized = false;
    this.reservationTypes = {
      SINGLE: 'Single reservation',
      GROUP: 'Group reservation',
    };
  }

  initialize(gtmId) {
    if (gtmId && !this.isInitialized) {
      this.tagmanager.initialize({
        gtmId,
        dataLayerName: gtmDataLayerName,
      });
      this.isInitialized = true;
    }
  }

  gtag(type, event, payload) {
    // eslint-disable-next-line camelcase
    const { gtag, google_tag_manager } = window || {};
    if (gtag) window.gtag(type, event, payload);
    // eslint-disable-next-line camelcase
    if (google_tag_manager && this.isInitialized) this.dataLayerPush({ event, ecommerce: payload });
  }

  dataLayerPush(payload) {
    return this.tagmanager.dataLayer({
      dataLayer: payload,
      dataLayerName: gtmDataLayerName,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  gtagEvent(event, payload) {
    return this.gtag('event', event, payload);
  }

  gtagEcommerceClear() {
    this.dataLayerPush({ ecommerce: null });
  }

  /**
   * @param {string} data.listingId
   * @param {string} data.listingName
   * @param {number} data.totalPrice
   * @param {string} data.currency
   * @param {string} data.checkInDate
   * @param {string} data.checkOutDate
   * @param {number} data.numberOfGuests
   * @param {string} data.pointOfSale
   */
  gtagEcommerceViewItem(data) {
    this.gtagEcommerceClear();
    this.gtagEvent('view_item', {
      currency: data.currency,
      value: data.totalPrice,
      items: [
        {
          item_id: data.listingId,
          item_name: data.listingName,
          affiliation: data.pointOfSale,
          item_category: data.checkInDate,
          item_category2: data.checkOutDate,
          item_category3: data.numberOfGuests.toString(),
          index: 0,
          price: data.totalPrice,
          quantity: 1,
        },
      ],
    });
  }

  /**
   * @param {number} data.totalPrice
   * @param {string} data.listingId
   * @param {string} data.listingName
   * @param {string} data.currency
   * @param {string} data.checkInDate
   * @param {string} data.checkOutDate
   * @param {string} data.numberOfGuests
   * @param {string} data.pointOfSale
   */
  gtagEcommerceBeginCheckout(data) {
    this.gtagEcommerceClear();
    this.gtagEvent('begin_checkout', {
      value: data.totalPrice,
      currency: data.currency,
      items: [
        {
          item_id: data.listingId,
          item_name: data.listingName,
          affiliation: data.pointOfSale,
          item_category: data.checkInDate,
          item_category2: data.checkOutDate,
          item_category3: data.numberOfGuests.toString(),
          index: 0,
          price: data.totalPrice, // fixme: fix for group reservations
          quantity: 1,
        },
      ],
    });
  }

  /**
   * @param {string} data.reservationId
   * @param {number} data.reservationTotalAmount
   * @param {string} data.listingId
   * @param {string} data.listingName
   * @param {string} data.currency
   * @param {string} data.taxes
   * @param {string} data.checkInDate
   * @param {string} data.checkOutDate
   * @param {string} data.numberOfGuests
   * @param {string} data.pointOfSale
   */
  gtagEcommercePurchase(data) {
    const payload = {
      transaction_id: data.reservationId,
      value: data.totalPrice,
      tax: data.taxes,
      currency: data.currency,
      items: [
        {
          item_id: data.listingId,
          item_name: data.listingName,
          affiliation: data.pointOfSale,
          item_category: data.checkInDate,
          item_category2: data.checkOutDate,
          item_category3: data.numberOfGuests.toString(),
          item_category4: data.reservationType,
          index: 0,
          price: data.totalPrice,
          quantity: 1,
        },
      ],
    };

    if (data.groupReservationId) {
      payload.item_category5 = data.groupReservationId;
    }
    this.gtagEcommerceClear();
    this.gtagEvent('purchase', payload);
  }

  gtagEcommerceGroupReservationsPurchase(data) {
    this.gtagEvent('purchase', {
      transaction_id: data.reservationId,
      value: data.totalPrice,
      // tax: 0,
      // shipping: 0,
      currency: data.currency,
      // coupon: '',
      items: [
        {
          item_id: data.listingId,
          item_name: data.listingName,
          affiliation: data.pointOfSale,
          item_category: data.checkInDate,
          item_category2: data.checkOutDate,
          item_category3: data.numberOfGuests.toString(),
          // coupon: '',
          // discount: 0,
          index: 0,
          price: data.totalPrice, // fixme: fix for group reservations
          quantity: 1,
        },
      ],
    });
  }
}

export default new GTAG();
