import axios from 'axios';

// TODO: provide our own service to get user location
const URL = 'https://geolocation-db.com/json/';

const getUserLocation = async () => {
  const res = await axios.get(URL);
  return res.data;
};

export default getUserLocation;
