import { useState } from 'react';
import useGoogleAnalyticsLegacy from './useGoogleAnalyticsLegacy';
import useGoogleAnalytics4 from './useGoogleAnalytics4';

// TODO will be removed on 1 July 2023 because of old GA won't be supported
//  steps to be done:
//  1) delete this file
//  2) delete useGoogleAnalyticsLegacy hook
//  3) rename useGoogleAnalytics4 hook to useGoogleAnalytics
//  4) remove react-ga from dependencies
const useGoogleAnalytics = (googleAnalyticsKey) => {
  const ReactGaLegacy = useGoogleAnalyticsLegacy(googleAnalyticsKey);
  const ReactGa4 = useGoogleAnalytics4(googleAnalyticsKey);
  const [isLegacy, setIsLegacy] = useState(true);

  const sendPageView = (location = window.location) => {
    if (isLegacy) {
      ReactGaLegacy.sendEvent(location);
    } else {
      ReactGa4.sendEvent(location);
    }
  };

  const initAnalytics = () => {
    if (!googleAnalyticsKey) return;

    const isLegacyKey = googleAnalyticsKey.includes('UA-');
    setIsLegacy(isLegacyKey);

    if (isLegacyKey) {
      ReactGaLegacy.initAnalytics();
    } else {
      ReactGa4.initAnalytics();
    }
  };

  const sendEvent = ({ category, action, label }) => {
    if (isLegacy) {
      ReactGaLegacy.sendEvent({ category, action, label });
    } else {
      ReactGa4.sendEvent({ category, action, label });
    }
  };

  return {
    initAnalytics,
    sendEvent,
    sendPageView,
  };
};

export default useGoogleAnalytics;
