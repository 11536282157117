import { useState, useCallback, useEffect, useMemo, useContext } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import cn from 'classnames';
import moment from 'moment';
import min from 'lodash/min';

import createStyles from '@guestyci/foundation/createStyles';
import t from '@guestyci/localize/t.macro';
import { Row, Col } from '@guestyci/foundation/Layout';
import RaisedButton from '@guestyci/foundation/RaisedButton';
import TextField from '@guestyci/foundation/TextField';

import useSearchValues from 'hooks/useSearchValues';
import useCreateGroupQuote from 'hooks/useCreateGroupQuote';
import useCreateGetQuote from 'hooks/useCreateGetQuote';
import useGetPathToNavigate from 'hooks/useGetPathToNavigate';
import useDio from 'hooks/useDio';

import { getGuestItems, generateSearchParams } from 'utils/index';
import { getSelectedRatePlans, calculateHostPayout } from 'utils/money.util';
import { DATE_FORMAT } from 'constants/date';
import PriceConverter from 'components/PriceConverter';
import { WebsiteSettingsContext } from 'context/WebsiteSettingsContext';

import { isEmpty } from 'lodash';
import { GroupReservationInputs } from './WidgetControls';
import SearchResults from './SearchResults';

const useStyles = createStyles(({ boxShadow, breakpoints: { create } }) => ({
  root: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    padding: 30,
    background: '#fff',
    boxShadow: boxShadow[1],
    borderRadius: 20,
    alignSelf: 'flex-start',
    width: 394,
    [create('xs')]: {
      width: '100%',
      padding: 10,
    },
    [create('md')]: {
      maxWidth: 394,
      padding: 30,
    },
    [create('xl')]: {
      marginTop: '-30px',
      position: 'sticky',
      top: 10,
      padding: 30,
    },
  },
  dateRangePicker: {
    [create('xs')]: {
      width: '100%',
      '& .DateRangePicker_picker': {
        left: '-75px!important',
      },
    },
  },
  formRoot: {
    width: '100%',
    flexDirection: 'column',
  },
  stepControl: {
    display: 'flex',
  },
  inputDataHeading: {
    fontSize: 14,
  },
  selectedRatePlanDataHeading: {
    fontSize: 14,
  },
  linkDisabled: {
    pointerEvents: 'none',
    background: '#D4D8E1!important',
  },
  bookNow: {
    width: '100%',
    maxWidth: 168,
    padding: '10px!important',
  },
}));

function generateGroupQuoteParams({
  dateRange,
  guestsCount,
  rooms,
  listingId,
  // TODO: What to do with pointofsale?
  // pointofsale,
}) {
  const quotes = Array.from({ length: rooms }).map(() => {
    return {
      checkInDateLocalized: moment(dateRange.startDate).format(DATE_FORMAT),
      checkOutDateLocalized: moment(dateRange.endDate).format(DATE_FORMAT),
      listingId,
    };
  });
  return {
    quotes,
    totalGuestsCount: guestsCount,
  };
}

const Controls = ({
  disableSearch,
  handleClick,
  isLoading,
  quoteData,
  selectedRatePlan,
  property,
  isGroupReservation,
  selectedGuestsCount,
  selectedRoomsCount,
}) => {
  const { dioTrack } = useDio();
  const { getPathWithLocale } = useGetPathToNavigate();
  const ratePlan = selectedRatePlan ? JSON.parse(selectedRatePlan) : {};

  const { money } = ratePlan;
  const { stepControl, bookNow, linkDisabled } = useStyles();
  const { listingId: queryListingId } = useSearchValues();
  const { search } = useLocation();
  const { quote, isSuccess } = quoteData;

  const handleSearchClick = () => {
    dioTrack('click_search_property_page', 'click', {
      listings: [
        {
          guests: selectedGuestsCount,
          rooms: selectedRoomsCount,
          listing_id: property._id,
          listing_nickname: property.nickname,
          item_title: property.title,
          currency: property.prices.currency,
          price: property.prices.basePrice,
          quantity: 1,
        },
      ],
    });
  };

  const handleBookClick = () => {
    dioTrack('click_book', 'click', {
      listings: [
        {
          guests: selectedGuestsCount,
          rooms: selectedRoomsCount,
          listing_id: property._id,
          listing_nickname: property.nickname,
          item_title: property.title,
          currency: property.prices.currency,
          price: property.prices.basePrice,
          quantity: 1,
        },
      ],
    });
  };

  const onSeachClick = () => {
    handleSearchClick();
    handleClick();
  };

  if (isSuccess && !!quote?.length && !isEmpty(ratePlan)) {
    let hostPayout = money?.hostPayout;

    if (quote.length > 1) {
      const { id: selectedRatePlanId } = ratePlan;
      const selectedRatePlans = getSelectedRatePlans(quote, selectedRatePlanId);
      hostPayout = calculateHostPayout(selectedRatePlans);
    }
    return (
      <Row justify="between">
        <Col>
          {money?.hostPayout && (
            <>
              <Row spacing={1}>
                {t('Total: <total></total>', {
                  total: () => (
                    <b>
                      <PriceConverter amount={hostPayout} currency={money?.currency} />
                    </b>
                  ),
                })}
              </Row>
              <TextField color="secondary" variant="h7">
                {t('Included fees & taxes')}
              </TextField>
            </>
          )}
        </Col>
        <Link
          data-qa="request-to-book"
          to={{
            pathname: getPathWithLocale(`/properties/${queryListingId}/checkout`),
            search,
            state: { quoteData, ratePlan: JSON.parse(selectedRatePlan), property, isGroupReservation },
          }}
          className={cn(bookNow, 'btn btn-colored', { [linkDisabled]: !selectedRatePlan })}
          onClick={handleBookClick}
        >
          {t('Continue to checkout')}
        </Link>
      </Row>
    );
  }
  return (
    <div className={stepControl}>
      <RaisedButton
        disabled={disableSearch}
        isLoading={isLoading}
        type="button"
        onClick={onSeachClick}
        className="w-100"
      >
        {t('Search')}
      </RaisedButton>
    </div>
  );
};

function generateQuoteParams({ dateRange, guestsCount, rooms, listingId, pointofsale }) {
  const params = {
    checkInDateLocalized: moment(dateRange.startDate).format(DATE_FORMAT),
    checkOutDateLocalized: moment(dateRange.endDate).format(DATE_FORMAT),
    guestsCount,
    listingId,
    pointofsale,
    rooms,
  };
  return params;
}

const BookNowGroupReserations = ({ property, isGroupReservation }) => {
  const history = useHistory();
  const { type } = property;
  const isMultiUnit = type === 'MTL';
  const {
    minOccupancy: queryGuestsCount,
    listingId: queryListingId,
    startDate: queryStartDate,
    endDate: queryEndDate,
    pointofsale,
    rooms,
  } = useSearchValues();
  const { root } = useStyles();
  const { contentConfiguration: { maxGuests } = {} } = useContext(WebsiteSettingsContext);

  const maxGuestsOptions = getGuestItems(min([maxGuests, property.accommodates]));
  const maxRoomsOptions = 10;
  const maxGuestsNumber = maxGuestsOptions.length;
  // eslint-disable-next-line no-nested-ternary
  // const guestsCount = queryGuestsCount ? (queryGuestsCount > maxGuestsNumber ? 1 : queryGuestsCount) : 1;
  const guestsCount = queryGuestsCount;
  // eslint-disable-next-line no-nested-ternary
  const roomsCount = rooms ? (rooms > maxRoomsOptions ? 1 : rooms) : 1;
  const [selectedGuestsCount, setSelectedGuestsCount] = useState(guestsCount);
  const [selectedRoomsCount, setSelectedRoomsCount] = useState(roomsCount);
  const [dateRange, setDateRange] = useState({ startDate: queryStartDate, endDate: queryEndDate });
  const [ratePlan, setRatePlan] = useState(null);
  const [quoteData, setQuoteData] = useState(null);
  const pushDates = useCallback(
    ({ dates, guests, rooms: roomCount }) => {
      const searchParams = generateSearchParams({ dates, guests, rooms: roomCount });
      history.push({ search: searchParams });
    },
    [history]
  );
  const finishGetQuote = useCallback(
    (data) => {
      const results = isGroupReservation ? data?.results || [] : [data];
      setQuoteData(results);
      pushDates({
        dates: dateRange,
        guests: selectedGuestsCount,
        rooms: selectedRoomsCount,
      });
    },
    [isGroupReservation, pushDates, dateRange, selectedGuestsCount, selectedRoomsCount]
  );

  const resetRatePlanAndQuote = () => {
    setRatePlan(null);
    setQuoteData(null);
  };

  const enableCreateQuote =
    !!dateRange.startDate && !!dateRange.endDate && !!selectedGuestsCount && !!selectedRoomsCount;
  const disableSearch = !enableCreateQuote;
  const singleUnitParams = generateQuoteParams({
    dateRange,
    guestsCount: selectedGuestsCount,
    rooms: selectedRoomsCount,
    listingId: queryListingId,
    pointofsale,
  });

  const groupParams = generateGroupQuoteParams({
    dateRange,
    guestsCount: selectedGuestsCount,
    rooms: selectedRoomsCount,
    listingId: queryListingId,
    pointofsale,
  });

  const {
    mutate: createGroupQuote,
    isLoading: isLoadingMultiUnitQuote,
    isSuccess: isCreatingGroupQuoteSuccess,
  } = useCreateGroupQuote({
    params: groupParams,
    onSuccess: finishGetQuote,
  });

  const {
    isLoading: isLoadingSingleUnitQuore,
    isSuccess: isCreatingQuoteSuccess,
    handleCreateQuote,
  } = useCreateGetQuote({
    params: singleUnitParams,
    onSuccess: finishGetQuote,
  });

  const createQuoteHandler = useMemo(() => {
    return isGroupReservation ? createGroupQuote : handleCreateQuote;
  }, [createGroupQuote, handleCreateQuote, isGroupReservation]);

  const handleSetGuestsCount = (e) => {
    const { value } = e.target;
    setSelectedGuestsCount(parseInt(value, 10) || 1);
    pushDates({
      dates: dateRange,
      guests: parseInt(value, 10) || 1,
      rooms: selectedRoomsCount,
    });
  };

  const handleSetRoomsCount = (e) => {
    const { value } = e.target;
    setSelectedRoomsCount(parseInt(value, 10) || 1);
    pushDates({
      dates: dateRange,
      guests: selectedGuestsCount,
      rooms: parseInt(value, 10) || 1,
    });
  };

  useEffect(() => {
    if (enableCreateQuote) {
      createQuoteHandler();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    resetRatePlanAndQuote();
  }, [selectedRoomsCount, selectedGuestsCount, dateRange, setRatePlan, isMultiUnit]);

  const handlePlanChange = useCallback((e) => {
    const { value } = e.target;
    setRatePlan(value);
  }, []);

  useEffect(() => {
    const ratePlans = quoteData ? quoteData[0]?.rates?.ratePlans : null;
    if (ratePlans?.length === 1) {
      setRatePlan(JSON.stringify(ratePlans[0].ratePlan));
    }
  }, [quoteData]);

  const isLoading = isLoadingSingleUnitQuore || isLoadingMultiUnitQuote;
  const isSuccess = isCreatingQuoteSuccess || isCreatingGroupQuoteSuccess;

  return (
    <div className={root}>
      <GroupReservationInputs
        dateRange={dateRange}
        setDateRange={setDateRange}
        listingId={queryListingId}
        isCreatingQuoteError={false}
        guestsCount={guestsCount}
        selectedGuestsCount={selectedGuestsCount}
        selectedRoomsCount={selectedRoomsCount}
        handleSetGuestsCount={handleSetGuestsCount}
        handleSetRoomsCount={handleSetRoomsCount}
        maxGuestsOptions={maxGuestsOptions}
        maxGuestsNumber={maxGuestsNumber}
        propertyType={type}
      />
      {isSuccess && !isLoading && (
        <SearchResults
          quoteData={quoteData}
          handlePlanChange={handlePlanChange}
          selectedRatePlan={ratePlan}
          accommodates={property?.accommodates}
          defaultCheckInTime={property.defaultCheckInTime}
        />
      )}
      <Controls
        disableSearch={disableSearch}
        handleClick={createQuoteHandler}
        isLoading={isLoading}
        quoteData={{ quote: quoteData, isSuccess }}
        selectedRatePlan={ratePlan}
        property={property}
        isGroupReservation={isGroupReservation}
        selectedGuestsCount={selectedGuestsCount}
        selectedRoomsCount={selectedRoomsCount}
      />
    </div>
  );
};

export default BookNowGroupReserations;
