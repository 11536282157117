import TextField from '@guestyci/foundation/TextField';
import t from '@guestyci/localize/t.macro/t.macro';
import createStyles from '@guestyci/foundation/createStyles';
import { useHistory } from 'react-router-dom';
import { CREDIT_CARD_PAYMENT_TYPE } from 'constants/constants';

const useStyles = createStyles(({ palette }) => ({
  wrapper: {
    marginTop: 15,
    backgroundColor: palette.success.background,
    padding: 15,
    maxWidth: 480,
    width: '100%',
    borderRadius: 4,
  },
}));

const ChargeNotice = () => {
  const { wrapper } = useStyles();
  const history = useHistory();
  const paymentType = history?.location?.state?.paymentType || CREDIT_CARD_PAYMENT_TYPE;
  const isCreditCardType = paymentType === CREDIT_CARD_PAYMENT_TYPE;

  if (!isCreditCardType) {
    return null;
  }

  return (
    <div className={wrapper}>
      <TextField color="dark">
        {t('Your card will be automatically charged according to our payment policies in up to 30 minutes.')}
      </TextField>
    </div>
  );
};

export default ChargeNotice;
