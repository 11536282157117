/* eslint-disable no-nested-ternary */
/* eslint-disable no-plusplus */
import { useMemo, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { mapValues, values } from 'lodash';
import createStyles from '@guestyci/foundation/createStyles';
import TextField from '@guestyci/foundation/TextField';

import { WebsiteSettingsContext } from 'context/WebsiteSettingsContext';
import About from 'components/About';
import ContactForm from 'components/ContactForm';
import FeaturedListings from 'components/FeaturedListings';
import Map from 'components/MapContainer';
import SearchBarWrapper from 'components/SearchBarWrapper';
import SearchBar from 'components/SearchBar';
import useGetListingsMarkers from 'hooks/useGetListingsMarkers';
import useDio from 'hooks/useDio';
import useGetPathToNavigate from 'hooks/useGetPathToNavigate';
import { generateSearchParams } from 'utils';
import getNightsCount from '../../utils/getNightsCount';

const useStyles = createStyles(({ breakpoints: { create }, boxShadow, spacer }) => ({
  root: {
    background: '#fff',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    overflow: 'hidden',
  },
  introductionImageWrapper: {
    width: '100%',
    position: 'relative',
    background: ({ image }) => `url(${image}) no-repeat center center`,
    backgroundSize: 'cover!important',
    height: ({ isFullScreen }) => (isFullScreen ? 'calc(100vh - 316px)' : 650),
    minHeight: 950,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [create('xs')]: {
      width: '100%',
      height: 'auto',
      flexDirection: 'column',
      alignItems: 'center',
    },
    [create('xl')]: {
      height: ({ isFullScreen }) => (isFullScreen ? 'calc(100svh - 316px)' : 650),
      alignItems: 'center',
    },
  },
  searchWrapper: {
    display: 'flex',
    borderRadius: 10,
    boxShadow: boxShadow[2],
    [create('xs')]: {
      position: 'relative',
      marginTop: spacer(8),
      bottom: -40,
      marginBottom: 230,
    },
    [create('lg')]: {
      height: 86,
      '& > div > div': {
        width: 'calc(100% - 60px)',
        paddingTop: spacer(2),
      },
    },
    [create('xl')]: {
      height: 86,
      '& > div > div': {
        width: 'calc(100% - 60px)',
        paddingTop: spacer(2),
      },
    },
  },
  contentWrapper: {
    display: 'flex',
    width: '100%',
    maxWidth: 1203,
    flexDirection: 'column',
    marginTop: 103,
    [create('xs')]: {
      overflow: 'hidden',
    },
    [create('xs')]: {
      minHeight: 90,
    },
    [create('lg')]: {
      minHeight: 230,
    },
    [create('md')]: {
      overflow: 'initial',
    },
  },
  mapWrapper: {
    marginBottom: 60,
    height: 705,
    [create('xs')]: {
      display: 'none',
    },
    [create('xl')]: {
      display: 'block',
    },
  },
  contactWrapper: {
    background: '#F5F5F5',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: '20px 0',
    [create('xs')]: {
      padding: '20px 10px',
    },
  },
  titleSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    [create('xs')]: {
      marginTop: 120,
    },
    [create('xl')]: {
      marginTop: 0,
    },
  },
  titleHeading: {
    marginBottom: 20,
    color: ({ titleColor }) => titleColor || '#fff',
    [create('xs')]: {
      fontSize: 32,
    },
    [create('lg')]: {
      fontSize: 64,
    },
  },
  subTitleHeading: {
    color: ({ titleColor }) => titleColor || '#fff',
    maxWidth: 1200,
  },
}));

const sectionsMap = ['INFO', 'MAP', 'LISTING', 'SERVICES', 'CONTACT'];

const HomePage = () => {
  const { dioTrack } = useDio();
  const history = useHistory();
  const { locale, isTranslationsEnabled } = useGetPathToNavigate();
  const pathname = isTranslationsEnabled ? `/${locale}` : '';

  const { data: markers, isLoading: isMarkersLoading } = useGetListingsMarkers();

  const {
    contentConfiguration: {
      pic: introductionImagePicture = '',
      sections,
      title,
      subTitle,
      colorTheme: { colors = {} } = {},
    } = {},
    displayOptions: { shouldShowCityFilter, shouldShowGuestsFilter, shouldShowMapOnHomePage } = {},
  } = useContext(WebsiteSettingsContext);

  const mappedSections = useMemo(() => values(mapValues(sections, (el, key) => ({ ...el, type: key }))), [sections]);
  const visibleSections = useMemo(() => {
    return mappedSections.reduce(
      (acc, section) => {
        if (section.active && sectionsMap.includes(section.type)) {
          acc[section.type] = true;
        }
        return acc;
      },
      {
        INFO: false,
        MAP: false,
        LISTING: false,
        SERVICES: false,
        CONTACT: false,
      }
    );
  }, [mappedSections]);

  const { LISTING, INFO, CONTACT } = visibleSections;

  const handleSearchClick = (clickData) => {
    dioTrack('click_search__page_button', 'click', clickData);
  };

  const onSearchHandler = (params) => {
    const { location, guests, dates, rooms } = params;
    const { startDate, endDate } = dates;
    const modifiedDateRange = { ...dates };

    if ((startDate && !endDate) || (!startDate && endDate)) {
      modifiedDateRange.startDate = startDate || moment(modifiedDateRange.endDate).add(-1, 'day');
      modifiedDateRange.endDate = endDate || moment(modifiedDateRange.startDate).add(1, 'day');
    }

    const nightsCount = getNightsCount(modifiedDateRange.startDate, modifiedDateRange.endDate);

    handleSearchClick({
      city: location?.city,
      country: location?.country,
      guests,
      rooms,
      num_night: nightsCount,
    });

    const searchParamsString = generateSearchParams({
      ...params,
      dates: modifiedDateRange,
    });
    history.push({ pathname: `${pathname}/properties`, search: searchParamsString });
  };

  useEffect(() => {
    dioTrack('view_home_page', 'pageview');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const isFullScreen = !LISTING && !shouldShowMapOnHomePage && !CONTACT && !INFO;
  const {
    root,
    introductionImageWrapper,
    searchWrapper,
    contentWrapper,
    mapWrapper,
    contactWrapper,
    titleSection,
    titleHeading,
    subTitleHeading,
  } = useStyles({
    image: introductionImagePicture,
    titleColor: colors?.homePageText,
    datesFullWidth: !shouldShowCityFilter && !shouldShowGuestsFilter,
    oneMissing: !shouldShowCityFilter || !shouldShowGuestsFilter,
    isFullScreen,
  });

  return (
    <div className={root}>
      <div className={introductionImageWrapper}>
        <div className={titleSection}>
          <TextField className={titleHeading} variant="h1">
            {title}
          </TextField>
          <TextField variant="h3" className={subTitleHeading}>
            {subTitle}
          </TextField>
        </div>
        <SearchBarWrapper className={searchWrapper}>
          <SearchBar onSubmit={onSearchHandler} />
        </SearchBarWrapper>
      </div>
      {!isFullScreen && (
        <>
          <div className={contentWrapper}>
            {INFO && <About />}
            {LISTING && <FeaturedListings context="homepage" />}
            {shouldShowMapOnHomePage && (
              <div className={mapWrapper}>
                <Map
                  isLoading={isMarkersLoading}
                  markers={markers}
                  containerStyles={{
                    height: '705px',
                    width: '100%',
                  }}
                />
              </div>
            )}
          </div>
          <ContactForm wrapper={contactWrapper} />
        </>
      )}
    </div>
  );
};

export default HomePage;
