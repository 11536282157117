import t from '@guestyci/localize/t.macro';

export const BANK_TRANSFER_PAYMENT_TYPE = 'bankTransfer';
export const CREDIT_CARD_PAYMENT_TYPE = 'card';

export const DEFAULT_RATE_PLAN_ID = 'default-rateplan-id';

export const COOKIE_POLICY_ACCEPTED = 'cookiePolicyAccepted';
export const COOKIE_POLICY_TERMS = () =>
  t(
    "This website uses cookies. \r\n We use cookies to personalise content and ads, to provide social media features and to analyse our traffic. We also share information about your use of our site with our social media, advertising and analytics partners who may combine it with other information that you've provided to them or that they've collected from your use of their services."
  );
export const ACCEPT_COOKIE_POLICY_BTN = 'Accept';

export const DISCOUNT_TYPE = {
  PERCENT: 'PERCENT',
  PERCENTAGE: 'PERCENTAGE',
  FIXED: 'FIXED',
};
export const INVOICE_ITEM_TYPE = {
  DISCOUNT: 'DISCOUNT',
};

export const getPropertyRoomTypes = () => ({
  BEDROOM: t('Bedroom'),
  SHARED_SPACE: t('Shared space'),
});

export const getBedTypes = () => ({
  KING_BED: (qty) => t('{qty, plural, one {King bed} other {King beds}}', { qty }),
  QUEEN_BED: (qty) => t('{qty, plural, one {Queen bed} other {Queen beds}}', { qty }),
  DOUBLE_BED: (qty) => t('{qty, plural, one {Double bed} other {Double beds}}', { qty }),
  SINGLE_BED: (qty) => t('{qty, plural, one {Single bed} other {Single beds}}', { qty }),
  SOFA_BED: (qty) => t('{qty, plural, one {Sofa bed} other {Sofa beds}}', { qty }),
  AIR_MATTRESS: (qty) => t('{qty, plural, one {Air mattress} other {Air mattresss}}', { qty }),
  BUNK_BED: (qty) => t('{qty, plural, one {Bunk bed} other {Bunk beds}}', { qty }),
  FLOOR_MATTRESS: (qty) => t('{qty, plural, one {Floor mattress} other {Floor mattresses}}', { qty }),
  WATER_BED: (qty) => t('{qty, plural, one {Water bed} other {Water beds}}', { qty }),
  TODDLER_BED: (qty) => t('{qty, plural, one {Toddler bed} other {Toddler beds}}', { qty }),
  CRIB: (qty) => t('{qty, plural, one {Crib} other {Cribs}}', { qty }),
  FULL_BATHROOM: (qty) => t('{qty, plural, one {Full bathroom} other {Full bathrooms}}', { qty }),
  HALF_BATHROOM: (qty) => t('{qty, plural, one {Half bathroom} other {Half bathrooms}}', { qty }),
});

export const getFeeTypes = () => ({
  EXTRA_BED: {
    label: t('Extra bed fee'),
  },
  CLEANING: {
    label: t('Cleanin fee'),
  },
  CANCELLATION: {
    label: t('Cancellation fee'),
  },
  ACCOMMODATION: {
    label: t('Accommodation fare fee'),
  },
  COUPON: {
    label: t('Coupon fee'),
  },
  HOST_SERVICE_FEE: {
    label: t('Host service fee'),
  },
});

export const getTaxTypes = () => ({
  LOCAL_TAX: {
    label: t('Local tax'),
  },
  CITY_TAX: {
    label: t('City tax'),
  },
  VAT: {
    label: t('VAT'),
  },
  GOODS_AND_SERVICES_TAX: {
    label: t('Goods and services tax'),
  },
  TOURISM_TAX: {
    label: t('Tourism tax'),
  },
  OTHER: {
    label: t('Other'),
  },
  STATE_TAX: {
    label: t('State tax'),
  },
  COUNTY_TAX: {
    label: t('Country tax'),
  },
  OCCUPANCY_TAX: {
    label: t('Occupancy tax'),
  },
  TRANSIENT_OCCUPANCY_TAX: {
    label: t('Transient occupancy tax'),
  },
  HOME_SHARING_TAX: {
    label: t('Home sharing tax'),
  },
  HARMONIZED_SALES_TAX: {
    label: t('Harmonized sales tax'),
  },
  MINIMUM_ALTERNATE_TAX: {
    label: t('Minimum alternate tax'),
  },
});

export const getAmenitiesTypes = () => ({
  SWIMMING_POOL: {
    label: t('Swimming pool'),
    value: 'Swimming pool',
  },
  ACCESSIBLE_HEIGHT_BED: {
    label: t('Accessible-height bed'),
    value: 'Accessible-height bed',
  },
  ACCESSIBLE_HEIGHT_TOILET: {
    label: t('Accessible-height toilet'),
    value: 'Accessible-height toilet',
  },
  AIR_CONDITIONING: {
    label: t('Air conditioning'),
    value: 'Air conditioning',
  },
  BABYSITTER_RECOMMENDATIONS: {
    label: t('Babysitter recommendations'),
    value: 'Babysitter recommendations',
  },
  BABY_BATH: {
    label: t('Baby bath'),
    value: 'Baby bath',
  },
  BABY_MONITOR: {
    label: t('Baby monitor'),
    value: 'Baby monitor',
  },
  BATHTUB: {
    label: t('Bathtub'),
    value: 'Bathtub',
  },
  BBQ_GRILL: {
    label: t('BBQ grill'),
    value: 'BBQ grill',
  },
  BEACH_ESSENTIALS: {
    label: t('Beach essentials'),
    value: 'Beach essentials',
  },
  BED_LINENS: {
    label: t('Bed linens'),
    value: 'Bed linens',
  },
  BREAKFAST: {
    label: t('Breakfast'),
    value: 'Breakfast',
  },
  CABLE_TV: {
    label: t('Cable TV'),
    value: 'Cable TV',
  },
  CARBON_MONOXIDE_DETECTOR: {
    label: t('Carbon monoxide detector'),
    value: 'Carbon monoxide detector',
  },
  CATS: {
    label: t('Cat(s)'),
    value: 'Cat(s)',
  },
  CHANGING_TABLE: {
    label: t('Changing table'),
    value: 'Changing table',
  },
  CHILDREN_BOOKS_AND_TOYS: {
    label: t('Children’s books and toys'),
    value: 'Children’s books and toys',
  },
  CHILDREN_DINNERWARE: {
    label: t('Children’s dinnerware'),
    value: 'Children’s dinnerware',
  },
  CLEANING_BEFORE_CHECKOUT: {
    label: t('Cleaning before checkout'),
    value: 'Cleaning before checkout',
  },
  COFFEE_MAKER: {
    label: t('Coffee maker'),
    value: 'Coffee maker',
  },
  COOKING_BASICS: {
    label: t('Cooking basics'),
    value: 'Cooking basics',
  },
  CRIB: {
    label: t('Crib'),
    value: 'Crib',
  },
  DISABLED_PARKING_SPOT: {
    label: t('Disabled parking spot'),
    value: 'Disabled parking spot',
  },
  DISHES_AND_SILVERWARE: {
    label: t('Dishes and silverware'),
    value: 'Dishes and silverware',
  },
  DISHWASHER: {
    label: t('Dishwasher'),
    value: 'Dishwasher',
  },
  DOGS: {
    label: t('Dog(s)'),
    value: 'Dog(s)',
  },
  DOORMAN: {
    label: t('Doorman'),
    value: 'Doorman',
  },
  DRYER: {
    label: t('Dryer'),
    value: 'Dryer',
  },
  ELEVATOR_IN_BUILDING: {
    label: t('Elevator in building'),
    value: 'Elevator in building',
  },
  ESSENTIALS: {
    label: t('Essentials'),
    value: 'Essentials',
  },
  ETHERNET_CONNECTION: {
    label: t('Ethernet connection'),
    value: 'Ethernet connection',
  },
  EV_CHARGER: {
    label: t('EV charger'),
    value: 'EV charger',
  },
  EXTRA_PILLOWS_AND_BLANKETS: {
    label: t('Extra pillows and blankets'),
    value: 'Extra pillows and blankets',
  },
  FIREPLACE_GUARDS: {
    label: t('Fireplace guards'),
    value: 'Fireplace guards',
  },
  FIRE_EXTINGUISHER: {
    label: t('Fire extinguisher'),
    value: 'Fire extinguisher',
  },
  FIRM_MATTRESS: {
    label: t('Firm mattress'),
    value: 'Firm mattress',
  },
  FIRST_AID_KIT: {
    label: t('First aid kit'),
    value: 'First aid kit',
  },
  FLAT_SMOOTH_PATHWAY_TO_FRONT_DOOR: {
    label: t('Flat smooth pathway to front door'),
    value: 'Flat smooth pathway to front door',
  },
  FREE_PARKING_ON_PREMISES: {
    label: t('Free parking on premises'),
    value: 'Free parking on premises',
  },
  GAME_CONSOLE: {
    label: t('Game console'),
    value: 'Game console',
  },
  GARDEN_OR_BACKYARD: {
    label: t('Garden or backyard'),
    value: 'Garden or backyard',
  },
  GRAB_RAILS_FOR_SHOWER_AND_TOILET: {
    label: t('Grab-rails for shower and toilet'),
    value: 'Grab-rails for shower and toilet',
  },
  GYM: {
    label: t('Gym'),
    value: 'Gym',
  },
  HAIR_DRYER: {
    label: t('Hair dryer'),
    value: 'Hair dryer',
  },
  HANGERS: {
    label: t('Hangers'),
    value: 'Hangers',
  },
  HEATING: {
    label: t('Heating'),
    value: 'Heating',
  },
  HIGH_CHAIR: {
    label: t('High chair'),
    value: 'High chair',
  },
  HOT_TUB: {
    label: t('Hot tub'),
    value: 'Hot tub',
  },
  HOT_WATER: {
    label: t('Hot water'),
    value: 'Hot water',
  },
  INDOOR_FIREPLACE: {
    label: t('Indoor fireplace'),
    value: 'Indoor fireplace',
  },
  INTERNET: {
    label: t('Internet'),
    value: 'Internet',
  },
  IRON: {
    label: t('Iron'),
    value: 'Iron',
  },
  KITCHEN: {
    label: t('Kitchen'),
    value: 'Kitchen',
  },
  LAPTOP_FRIENDLY_WORKSPACE: {
    label: t('Laptop friendly workspace'),
    value: 'Laptop friendly workspace',
  },
  LONG_TERM_STAYS_ALLOWED: {
    label: t('Long term stays allowed'),
    value: 'Long term stays allowed',
  },
  LUGGAGE_DROPOFF_ALLOWED: {
    label: t('Luggage dropoff allowed'),
    value: 'Luggage dropoff allowed',
  },
  MICROWAVE: {
    label: t('Microwave'),
    value: 'Microwave',
  },
  OTHER_PET: {
    label: t('Other pet(s)'),
    value: 'Other pet(s)',
  },
  OUTLET_COVERS: {
    label: t('Outlet covers'),
    value: 'Outlet covers',
  },
  OVEN: {
    label: t('Oven'),
    value: 'Oven',
  },
  PACK_N_PLAY_TRAVEL_CRIB: {
    label: t('Pack ’n Play/travel crib'),
    value: 'Pack ’n Play/travel crib',
  },
  PATH_TO_ENTRANCE_LIT_AT_NIGHT: {
    label: t('Path to entrance lit at night'),
    value: 'Path to entrance lit at night',
  },
  PATIO_OR_BALCONY: {
    label: t('Patio or balcony'),
    value: 'Patio or balcony',
  },
  PETS_ALLOWED: {
    label: t('Pets allowed'),
    value: 'Pets allowed',
  },
  PETS_LIVE_ON_THIS_PROPERTY: {
    label: t('Pets live on this property'),
    value: 'Pets live on this property',
  },
  POCKET_WIFI: {
    label: t('Pocket wifi'),
    value: 'Pocket wifi',
  },
  POOL: {
    label: t('Pool'),
    value: 'Pool',
  },
  PRIVATE_ENTRANCE: {
    label: t('Private entrance'),
    value: 'Private entrance',
  },
  REFRIGERATOR: {
    label: t('Refrigerator'),
    value: 'Refrigerator',
  },
  ROLL_IN_SHOWER_WITH_SHOWER_BENCH_OR_CHAIR: {
    label: t('Roll-in shower with shower bench or chair'),
    value: 'Roll-in shower with shower bench or chair',
  },
  ROOM_DARKENING_SHADES: {
    label: t('Room-darkening shades'),
    value: 'Room-darkening shades',
  },
  SHAMPOO: {
    label: t('Shampoo'),
    value: 'Shampoo',
  },
  SINGLE_LEVEL_HOME: {
    label: t('Single level home'),
    value: 'Single level home',
  },
  SMOKE_DETECTOR: {
    label: t('Smoke detector'),
    value: 'Smoke detector',
  },
  SMOKING_ALLOWED: {
    label: t('Smoking allowed'),
    value: 'Smoking allowed',
  },
  STAIR_GATES: {
    label: t('Stair gates'),
    value: 'Stair gates',
  },
  STEP_FREE_ACCESS: {
    label: t('Step-free access'),
    value: 'Step-free access',
  },
  STOVE: {
    label: t('Stove'),
    value: 'Stove',
  },
  SUITABLE_FOR_CHILDREN: {
    label: t('Suitable for children (2-12 years)'),
    value: 'Suitable for children (2-12 years)',
  },
  SUITABLE_FOR_INFANTS: {
    label: t('Suitable for infants (under 2 years)'),
    value: 'Suitable for infants (under 2 years)',
  },
  TABLE_CORNER_GUARDS: {
    label: t('Table corner guards'),
    value: 'Table corner guards',
  },
  TUB_WITH_SHOWER_BENCH: {
    label: t('Tub with shower bench'),
    value: 'Tub with shower bench',
  },
  TV: {
    label: t('TV'),
    value: 'TV',
  },
  WASHER: {
    label: t('Washer'),
    value: 'Washer',
  },
  WIDE_CLEARANCE_TO_BED: {
    label: t('Wide clearance to bed'),
    value: 'Wide clearance to bed',
  },
  WIDE_CLEARANCE_TO_SHOWER_AND_TOILET: {
    label: t('Wide clearance to shower and toilet'),
    value: 'Wide clearance to shower and toilet',
  },
  WIDE_DOORWAY: {
    label: t('Wide doorway'),
    value: 'Wide doorway',
  },
  WIDE_HALLWAY_CLEARANCE: {
    label: t('Wide hallway clearance'),
    value: 'Wide hallway clearance',
  },
  WINDOW_GUARDS: {
    label: t('Window guards'),
    value: 'Window guards',
  },
  WIRELESS_INTERNET: {
    label: t('Wireless Internet'),
    value: 'Wireless Internet',
  },
});

export const getPropertyTypes = () => ({
  APARTMENT: {
    label: t('Apartment'),
    value: 'Apartment',
  },
  HOUSE: {
    label: t('House'),
    value: 'House',
  },
  LOFT: {
    label: t('Loft'),
    value: 'Loft',
  },
  BOAT: {
    label: t('Boat'),
    value: 'Boat',
  },
  CAMPER_RV: {
    label: t('Camper/RV'),
    value: 'Camper/RV',
  },
  CONDOMINIUM: {
    label: t('Condominium'),
    value: 'Condominium',
  },
  CHALET: {
    label: t('Chalet'),
    value: 'Chalet',
  },
  BED_Breakfast: {
    label: t('Bed & Breakfast'),
    value: 'Bed & Breakfast',
  },
  VILLA: {
    label: t('Villa'),
    value: 'Villa',
  },
  TENT: {
    label: t('Tent'),
    value: 'Tent',
  },
  OTHER: {
    label: t('Other'),
    value: 'Other',
  },
  CABIN: {
    label: t('Cabin'),
    value: 'Cabin',
  },
  TOWNHOUSE: {
    label: t('Townhouse'),
    value: 'Townhouse',
  },
  BUNGALOW: {
    label: t('Bungalow'),
    value: 'Bungalow',
  },
  HUT: {
    label: t('Hut'),
    value: 'Hut',
  },
  DORM: {
    label: t('Dorm'),
    value: 'Dorm',
  },
  PARKING_SPACE: {
    label: t('Parking Space'),
    value: 'Parking Space',
  },
  PLANE: {
    label: t('Plane'),
    value: 'Plane',
  },
  TREEHOUSE: {
    label: t('Treehouse'),
    value: 'Treehouse',
  },
  YURT: {
    label: t('Yurt'),
    value: 'Yurt',
  },
  TIPI: {
    label: t('Tipi'),
    value: 'Tipi',
  },
  IGLOO: {
    label: t('Igloo'),
    value: 'Igloo',
  },
  EARTH_HOUSE: {
    label: t('Earth House'),
    value: 'Earth House',
  },
  ISLAND: {
    label: t('Island'),
    value: 'Island',
  },
  CAVE: {
    label: t('Cave'),
    value: 'Cave',
  },
  CASTLE: {
    label: t('Castle'),
    value: 'Castle',
  },
  STUDIO: {
    label: t('Studio'),
    value: 'Studio',
  },
  COTTAGE: {
    label: t('Cottage'),
    value: 'Cottage',
  },
});

export const MEAL_PLANS = {
  BREAKFAST: 'breakfast',
  LUNCH: 'lunch',
  DINNER: 'dinner',
  ALL_INCLUSIVE: 'all_inclusive',
};

export const getMealPlansTranslations = () => ({
  [MEAL_PLANS.BREAKFAST]: t('Breakfast'),
  [MEAL_PLANS.LUNCH]: t('Lunch'),
  [MEAL_PLANS.DINNER]: t('Dinner'),
});

export const ALLOWED_POINT_OF_SALE = ['google'];
