import { useCallback, useMemo } from 'react';
import gtagService from '../services/GTAG';

const useGoogleTagManager = (gtmId) => {
  const initGoogleTagManager = useCallback(() => {
    gtagService.initialize(gtmId);
  }, [gtmId]);

  const gtagEvent = useCallback((event, payload) => {
    return gtagService.gtagEvent(event, payload);
  }, []);

  /**
   * @param {string} listingId
   * @param {string} listingName
   * @param {number} totalPrice
   * @param {string} currency
   * @param {string} checkInDate
   * @param {string} checkOutDate
   * @param {number} numberOfGuests
   * @param {string} pointOfSale
   */
  const gtagEcommerceViewItem = useCallback((data) => {
    gtagService.gtagEcommerceViewItem(data);
  }, []);

  /**
   * @param {number} data.totalPrice
   * @param {string} data.listingId
   * @param {string} data.listingName
   * @param {string} data.currency
   * @param {string} data.checkInDate
   * @param {string} data.checkOutDate
   * @param {string} data.numberOfGuests
   * @param {string} data.pointOfSale
   */
  const gtagEcommerceBeginCheckout = useCallback((data) => {
    gtagService.gtagEcommerceBeginCheckout(data);
  }, []);

  /**
   * @param {string} data.reservationId
   * @param {number} data.totalPrice
   * @param {string} data.listingId
   * @param {string} data.listingName
   * @param {string} data.currency
   * @param {string} data.checkInDate
   * @param {string} data.checkOutDate
   * @param {string} data.numberOfGuests
   * @param {string} data.pointOfSale
   */
  const gtagEcommercePurchase = useCallback((data) => {
    gtagService.gtagEcommercePurchase(data);
  }, []);

  /**
   * @param {string} data.reservationId
   * @param {number} data.totalPrice
   * @param {string} data.listingId
   * @param {string} data.listingName
   * @param {string} data.currency
   * @param {string} data.checkInDate
   * @param {string} data.checkOutDate
   * @param {string} data.numberOfGuests
   * @param {string} data.pointOfSale
   */
  const gtagEcommerceGroupReservationsPurchase = useCallback((data) => {
    gtagService.gtagEcommerceGroupReservationsPurchase(data);
  }, []);

  const reservationTypes = useMemo(() => gtagService.reservationTypes, []);

  return {
    initGoogleTagManager,
    gtagEvent,
    gtagEcommerceViewItem,
    gtagEcommerceBeginCheckout,
    gtagEcommercePurchase,
    gtagEcommerceGroupReservationsPurchase,
    reservationTypes,
  };
};

export default useGoogleTagManager;
