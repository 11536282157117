import { useLocation, useHistory } from 'react-router-dom';

import Spinner from '@guestyci/foundation/Spinner';
import useFeatureToggle from '@guestyci/feature-toggle-fe/useFeatureToggle';

import useSearchValues from 'hooks/useSearchValues';
import FullPageLoader from 'components/FullPageLoader';

import useGetListingPaymentProvider from 'hooks/useGetListingPaymentProvider';
import useGetListingById from 'hooks/useGetListingById';
import useGetPathToNavigate from 'hooks/useGetPathToNavigate';
import { GROUP_RESERVATIONS } from 'constants/featureToggleNames';

import CheckOutInstantStripe from './CheckOutInstantStripe';
import CheckOutInstantAmaryllis from './CheckOutInstantAmaryllis';

const STRIPE = 'stripe';
const AMARYLLIS = 'amaryllis';

const CheckOutInstantContainer = () => {
  const history = useHistory();
  const { state } = useLocation();
  const { getPathWithLocale } = useGetPathToNavigate();
  const redirectPath = getPathWithLocale('/properties');

  const [, isGroupReservationsFTEnabled] = useFeatureToggle(GROUP_RESERVATIONS);

  if (isGroupReservationsFTEnabled) {
    if (!state?.quoteData?.quote?.length || !state?.ratePlan?._id) {
      history.push(redirectPath);
    }
  } else if (!state?.quoteId || !state?.ratePlanId) {
    history.push(redirectPath);
  }

  const { listingId } = useSearchValues();
  const { property, isGettingListing } = useGetListingById({ id: listingId });
  const { data: listingPaymentProvider, isLoading } = useGetListingPaymentProvider({ listingId });
  const { providerAccountId, providerType, _id: providerId } = listingPaymentProvider || {};

  if (isLoading || isGettingListing) {
    return <FullPageLoader />;
  }

  switch (providerType) {
    case STRIPE:
      return <CheckOutInstantStripe providerAccountId={providerAccountId} property={property} />;
    case AMARYLLIS:
      return <CheckOutInstantAmaryllis providerId={providerId} property={property} />;
    default:
      return <Spinner />;
  }
};

export default CheckOutInstantContainer;
