import { useCallback, useContext, useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import ReactPixel from 'react-facebook-pixel';
import { WebsiteSettingsContext } from 'context/WebsiteSettingsContext';

export default function useFacebookPixel({ facebookPixelCode = null, isAnalyticsAvailable } = {}) {
  const DEFAULT_PATH = `${window.location.pathname}${window.location.search}` || '/';
  const { pathname } = useLocation();
  const history = useHistory();
  const prevPathname = useRef('');
  const initCompleted = useRef(false);
  const { cookiesPolicyAccepted } = useContext(WebsiteSettingsContext);

  const initAnalytics = useCallback(() => {
    const listenURLChanges = () => {
      history.listen((location) => {
        ReactPixel.pageView(`${location.pathname}${location.search}`);
      });
    };
    // https://developers.facebook.com/docs/facebook-pixel/pixel-with-ads/conversion-tracking#advanced_match
    const advancedMatching = {};
    const options = {
      autoConfig: true, // set pixel's autoConfig
      debug: false, // enable logs
    };

    ReactPixel.init(facebookPixelCode, advancedMatching, options);
    if (window.location.pathname === DEFAULT_PATH) {
      ReactPixel.pageView(DEFAULT_PATH);
    }
    listenURLChanges();
    initCompleted.current = true;
  }, [history, facebookPixelCode, initCompleted, DEFAULT_PATH]);

  const fbtrack = useCallback((title, payload) => {
    return ReactPixel.trackCustom(title, payload);
  }, []);

  useEffect(() => {
    prevPathname.current = pathname;
  }, [pathname]);

  useEffect(() => {
    if (!facebookPixelCode || !isAnalyticsAvailable || initCompleted.current) {
      return;
    }
    if (Number(cookiesPolicyAccepted) === 1) {
      initAnalytics();
    }
  }, [cookiesPolicyAccepted, facebookPixelCode, initAnalytics, isAnalyticsAvailable]);

  return {
    initAnalytics,
    fbtrack,
  };
}
