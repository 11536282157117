import { useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Col } from '@guestyci/foundation/Layout';
import TextField from '@guestyci/foundation/TextField';
import t from '@guestyci/localize/t.macro/t.macro';
import createStyles from '@guestyci/foundation/createStyles';
import useFeatureToggle from '@guestyci/feature-toggle-fe/useFeatureToggle';

import { WebsiteSettingsContext } from 'context/WebsiteSettingsContext';
import successInstant from 'assets/baners/successInstant.svg';

import { CREDIT_CARD_PAYMENT_TYPE } from 'constants/constants';
import { BANK_TRANSFER, ZERO_AMOUNT_NOTIFICATION } from 'constants/featureToggleNames';
import useGetPathToNavigate from 'hooks/useGetPathToNavigate';
import ChargeNotice from 'components/ChargeNotice';

const useStyles = createStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    background: '#fff',
  },
  text: {
    textAlign: 'center',
  },
  content: {
    minHeight: '71vh',
    paddingBottom: 40,
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: 1500,
    background: '#FAFAFA',
    width: '100%',
    textAlign: 'center',
  },
}));

const useConfirmationPagePayload = () => {
  const history = useHistory();
  const [, isBankTransferEnabled] = useFeatureToggle(BANK_TRANSFER);

  const paymentType = history?.location?.state?.paymentType || CREDIT_CARD_PAYMENT_TYPE;
  const { contentConfiguration } = useContext(WebsiteSettingsContext);

  if (!isBankTransferEnabled) {
    return contentConfiguration?.sections?.CONFIRMATION_PAGE?.payload;
  }
  return paymentType === CREDIT_CARD_PAYMENT_TYPE
    ? contentConfiguration?.sections?.CONFIRMATION_PAGE?.payload
    : contentConfiguration?.sections?.CONFIRMATION_PAGE_BANK_TRANSFER?.payload;
};

const InstantSuccessPage = () => {
  const [, isZeroAmountNotificationEnabled] = useFeatureToggle(ZERO_AMOUNT_NOTIFICATION);
  const confirmationPage = useConfirmationPagePayload();
  const { getPathWithLocale } = useGetPathToNavigate();
  const redirectPath = getPathWithLocale('/properties');
  const { root, text, content } = useStyles();
  const {
    companyInfo: { email = null },
  } = useContext(WebsiteSettingsContext);
  const { image, title, description } = confirmationPage || {};
  const emailText = t('Got a question? Contact us at');
  return (
    <div className={root}>
      <Col align="center" className={content}>
        <TextField className="mt-6" variant="h2">
          {title || t('Thanks for booking with us!')}
        </TextField>
        <TextField className={text}>{description || t("You'll receive a confirmation email shortly.")}</TextField>
        <img src={image || successInstant} alt="successInstant" />
        {email && <TextField>{`${emailText} ${email}`}</TextField>}
        {isZeroAmountNotificationEnabled && <ChargeNotice />}
        <Link to={redirectPath} className="btn btn-colored mt-6">
          <TextField variant="h3" color="white">
            {t('Browse more properties')}
          </TextField>
        </Link>
      </Col>
    </div>
  );
};

export default InstantSuccessPage;
