/* eslint-disable no-param-reassign */
import t from '@guestyci/localize/t.macro';

// TODO: replace by getAmenitiesTypes from constants
export const amenitiesWithTranslation = () => ({
  elevator: {
    icon: 'elevator',
    lowerCase: 'elevator',
    text: t('Elevator'),
  },
  parking: {
    icon: 'parking',
    lowerCase: 'free parking on premises',
    text: t('Free Parking on Premises'),
  },
  parkingPaid: {
    icon: 'parking',
    lowerCase: 'paid parking off premises',
    text: t('Paid Parking off Premises'),
  },
  parkingStreet: {
    icon: 'parking',
    lowerCase: 'free parking on street',
    text: t('Free Parking on Street'),
  },
  wheelchair: {
    icon: 'wheelchair',
    lowerCase: 'wheelchair accessible',
    text: t('Wheelchair Accessible'),
  },
  gym: {
    icon: 'gym',
    lowerCase: 'gym',
    text: t('Gym'),
  },
  pool: {
    icon: 'pool',
    lowerCase: 'swimming pool',
    text: t('Swimming Pool'),
  },
  poolPrivate: {
    icon: 'pool',
    lowerCase: 'private pool',
    text: t('Private Pool'),
  },
  poolIndoor: {
    icon: 'pool',
    lowerCase: 'indoor pool',
    text: t('Indoor Pool'),
  },
  poolOutdoor: {
    icon: 'pool',
    lowerCase: 'outdoor pool',
    text: t('Outdoor Pool'),
  },
  hot_tub: {
    icon: 'hot_tub',
    lowerCase: 'hot tub',
    text: t('Hot Tub'),
  },
  air_condition: {
    icon: 'air_condition',
    lowerCase: 'air conditioning',
    text: t('Air Conditioning'),
  },
  heating: {
    icon: 'heating',
    lowerCase: 'heating',
    text: t('Heating'),
  },
  fireplace: {
    icon: 'fireplace',
    lowerCase: 'indoor fireplace',
    text: t('Indoor Fireplace'),
  },
  wifi: {
    icon: 'wifi',
    lowerCase: 'wireless internet',
    text: t('Wireless Internet'),
  },
  internet: {
    icon: 'internet',
    lowerCase: 'internet',
    text: t('Internet'),
  },
  laptop: {
    icon: 'laptop',
    lowerCase: 'laptop friendly workspace',
    text: t('Laptop Friendly Workspace'),
  },
  kitchen: {
    icon: 'kitchen',
    lowerCase: 'kitchen',
    text: t('Kitchen'),
  },
  washer: {
    icon: 'washer',
    lowerCase: 'washer',
    text: t('Washer'),
  },
  dryer: {
    icon: 'dryer',
    lowerCase: 'dryer',
    text: t('Dryer'),
  },
  hair_dryer: {
    icon: 'hair_dryer',
    lowerCase: 'hair dryer',
    text: t('Hair Dryer'),
  },
  iron: {
    icon: 'iron',
    lowerCase: 'iron',
    text: t('Iron'),
  },
  cable_tv: {
    icon: 'tv',
    lowerCase: 'cable tv',
    text: t('Cable TV'),
  },
  tv: {
    icon: 'tv',
    lowerCase: 'tv',
    text: t('TV'),
  },
  keypad: {
    icon: 'keypad',
    lowerCase: 'keypad',
    text: t('Keypad'),
  },
  essentials: {
    icon: 'essentials',
    lowerCase: 'essentials',
    text: t('Essentials'),
  },
  first_aid: {
    icon: 'first_aid',
    lowerCase: 'first aid kit',
    text: t('First Aid Kit'),
  },
  shampoo: {
    icon: 'shampoo',
    lowerCase: 'shampoo',
    text: t('Shampoo'),
  },
  hangers: {
    icon: 'hangers',
    lowerCase: 'hangers',
    text: t('Hangers'),
  },
  smoke_detector: {
    icon: 'smoke_detector',
    lowerCase: 'smoke detector',
    text: t('Smoke Detector'),
  },
  CM_detector: {
    icon: 'CM_detector',
    lowerCase: 'carbon monoxide detector',
    text: t('Carbon Monoxide Detector'),
  },
  fire_extinguisher: {
    icon: 'fire_extinguisher',
    lowerCase: 'fire extinguisher',
    text: t('Fire Extinguisher'),
  },
  safety_card: {
    icon: 'safety_card',
    lowerCase: 'safety card',
    text: t('Safety Card'),
  },
  buzzer: {
    icon: 'buzzer',
    lowerCase: 'buzzer/wireless intercom',
    text: t('Buzzer/Wireless Intercom'),
  },
  private_entrance: {
    icon: 'private_entrance',
    lowerCase: 'private entrance',
    text: t('Private entrance'),
  },
  private_livingroom: {
    icon: 'private_livingroom',
    lowerCase: 'private livingroom',
    text: t('Private livingroom'),
  },
  locker: {
    icon: 'locker',
    lowerCase: 'lock on bedroom door',
    text: t('Lock on Bedroom Door'),
  },
  doorman: {
    icon: 'doorman',
    lowerCase: 'doorman',
    text: t('Doorman'),
  },
  lockbox: {
    icon: 'lockbox',
    lowerCase: 'lockbox',
    text: t('Lockbox'),
  },
  smartlock: {
    icon: 'smartlock',
    lowerCase: 'smartlock',
    text: t('Smartlock'),
  },
  family_friendly: {
    icon: 'family_friendly',
    lowerCase: 'family/kid friendly',
    text: t('Family/Kid Friendly'),
  },
  smoking: {
    icon: 'smoking',
    lowerCase: 'smoking allowed',
    text: t('Smoking Allowed'),
  },
  events: {
    icon: 'events',
    lowerCase: 'suitable for events',
    text: t('Suitable for Events'),
  },
  checkin_24_hours: {
    icon: 'checkin',
    lowerCase: '24-hour check-in',
    text: t('24-Hour Check-in'),
  },
  self_checkin: {
    icon: 'checkin',
    lowerCase: 'self check-in',
    text: t('Self Check-in'),
  },
  breakfast: {
    icon: 'breakfast',
    lowerCase: 'breakfast',
    text: t('Breakfast'),
  },
  pets_allowed: {
    icon: 'pets_allowed',
    lowerCase: 'pets allowed',
    text: t('Pets Allowed'),
  },
  pets_live: {
    icon: 'pets_live',
    lowerCase: 'pets live on this property',
    text: t('Pets live on this property'),
  },
  baby: {
    icon: 'baby',
    lowerCase: 'baby monitor',
    text: t('Baby Monitor'),
  },
  baby_bath: {
    icon: 'baby',
    lowerCase: 'baby bath',
    text: t('Baby bath'),
  },
  babysitter_recommendations: {
    icon: 'baby',
    lowerCase: 'babysitter recommendations',
    text: t('Babysitter recommendations'),
  },
  pack_n_play_travel_crib: {
    icon: 'baby',
    lowerCase: 'pack ’n play/travel crib',
    text: t('Pack ’n Play/travel crib'),
  },
  childrens_books_and_toys: {
    icon: 'family_friendly',
    lowerCase: "children's books and toys",
    text: t("Children's books and toys"),
  },
  childrens_dinnerware: {
    icon: 'family_friendly',
    lowerCase: "children's dinnerware",
    text: t("Children's dinnerware"),
  },
  bathtub: {
    icon: 'bath',
    lowerCase: 'bathtub',
    text: t('Bathtub'),
  },
  tub_with_shower_bench: {
    icon: 'bath',
    lowerCase: 'tub with shower bench',
    text: t('Tub with shower bench'),
  },
  accessible_height_bed: {
    icon: 'wheelchair',
    lowerCase: 'accessible-height bed',
    text: t('Accessible-height bed'),
  },
  accessible_height_toilet: {
    icon: 'wheelchair',
    lowerCase: 'accessible-height toilet',
    text: t('Accessible-height toilet'),
  },
  bed_linens: {
    icon: 'bed',
    lowerCase: 'bed linens',
    text: t('Bed linens'),
  },
  wide_clearance_to_bed: {
    icon: 'bed',
    lowerCase: 'wide clearance to bed',
    text: t('Wide clearance to bed'),
  },
  stair_gates: {
    icon: 'guard',
    lowerCase: 'stair gates',
    text: t('Stair gates'),
  },
  window_guards: {
    icon: 'guard',
    lowerCase: 'window guards',
    text: t('Window guards'),
  },
  table_corner_guards: {
    icon: 'guard',
    lowerCase: 'table corner guards',
    text: t('Table corner guards'),
  },
  fireplace_guards: {
    icon: 'guard',
    lowerCase: 'fireplace guards',
    text: t('Fireplace guards'),
  },
  outlet_covers: {
    icon: 'guard',
    lowerCase: 'outlet covers',
    text: t('Outlet covers'),
  },
  disabled_parking_spot: {
    icon: 'parking',
    lowerCase: 'disabled parking spot',
    text: t('Disabled parking spot'),
  },
  wide_hallway_clearance: {
    icon: 'accessibility',
    lowerCase: 'wide hallway clearance',
    text: t('Wide hallway clearance'),
  },
  step_free_access: {
    icon: 'accessibility',
    lowerCase: 'step-free access',
    text: t('Step-free access'),
  },
  wide_doorway: {
    icon: 'accessibility',
    lowerCase: 'wide doorway',
    text: t('Wide doorway'),
  },
  flat_smooth_pathway_to_front_door: {
    icon: 'accessibility',
    lowerCase: 'flat smooth pathway to front door',
    text: t('Flat smooth pathway to front door'),
  },
  path_to_entrance_lit_at_night: {
    icon: 'accessibility',
    lowerCase: 'path to entrance lit at night',
    text: t('Path to entrance lit at night'),
  },
  grab_rails_for_shower_and_toilet: {
    icon: 'accessibility',
    lowerCase: 'grab-rails for shower and toilet',
    text: t('Grab-rails for shower and toilet'),
  },
  roll_in_shower_with_shower_bench_or_chair: {
    icon: 'accessibility',
    lowerCase: 'roll-in shower with shower bench or chair',
    text: t('Roll-in shower with shower bench or chair'),
  },
  wide_clearance_to_shower_and_toilet: {
    icon: 'accessibility',
    lowerCase: 'wide clearance to shower and toilet',
    text: t('Wide clearance to shower and toilet'),
  },
  changing_table: {
    icon: 'table',
    lowerCase: 'changing table',
    text: t('Changing table'),
  },
  high_chair: {
    icon: 'chair',
    lowerCase: 'high chair',
    text: t('High chair'),
  },
  room_darkening_shades: {
    icon: 'shades',
    lowerCase: 'room darkening shades',
    text: t('Room darkening shades'),
  },
  game_console: {
    icon: 'console',
    lowerCase: 'game console',
    text: t('Game console'),
  },
  microwave: {
    icon: 'microwave',
    lowerCase: 'microwave',
    text: t('Microwave'),
  },
  coffee_maker: {
    icon: 'coffee',
    lowerCase: 'coffee maker',
    text: t('Coffee maker'),
  },
  refrigerator: {
    icon: 'refrigerator',
    lowerCase: 'refrigerator',
    text: t('Refrigerator'),
  },
  dishwasher: {
    icon: 'dishwasher',
    lowerCase: 'dishwasher',
    text: t('Dishwasher'),
  },
  dishes_and_silverware: {
    icon: 'dishes',
    lowerCase: 'dishes and silverware',
    text: t('Dishes and silverware'),
  },
  cookware: {
    icon: 'kitchen',
    lowerCase: 'cookware',
    text: t('Cookware'),
  },
  oven: {
    icon: 'oven',
    lowerCase: 'oven',
    text: t('Oven'),
  },
  stove: {
    icon: 'stove',
    lowerCase: 'stove',
    text: t('Stove'),
  },
  bbq_grill: {
    icon: 'bbq',
    lowerCase: 'bbq grill',
    text: t('BBQ grill'),
  },
  patio_or_balcony: {
    icon: 'patio',
    lowerCase: 'patio or balcony',
    text: t('Patio or balcony'),
  },
  garden_or_backyard: {
    icon: 'garden',
    lowerCase: 'garden or backyard',
    text: t('Garden or backyard'),
  },
  beach_essentials: {
    icon: 'beach',
    lowerCase: 'beach essentials',
    text: t('Beach essentials'),
  },
});

const allAmenities = [
  { icon: 'elevator', text: 'Elevator' },
  { icon: 'parking', text: 'Free Parking on Premises' },
  { icon: 'parking', text: 'Paid Parking off Premises' },
  { icon: 'parking', text: 'Free Parking on Street' },
  { icon: 'wheelchair', text: 'Wheelchair Accessible' },
  { icon: 'gym', text: 'Gym' },
  { icon: 'pool', text: 'Swimming Pool' },
  { icon: 'pool', text: 'Private Pool' },
  { icon: 'pool', text: 'Indoor Pool' },
  { icon: 'pool', text: 'Outdoor Pool' },
  { icon: 'hot_tub', text: 'Hot Tub' },
  { icon: 'air_condition', text: 'Air Conditioning' },
  { icon: 'heating', text: 'Heating' },
  { icon: 'fireplace', text: 'Indoor Fireplace' },
  { icon: 'wifi', text: 'Wireless Internet' },
  { icon: 'internet', text: 'Internet' },
  { icon: 'laptop', text: 'Laptop Friendly Workspace' },
  { icon: 'kitchen', text: 'Kitchen' },
  { icon: 'washer', text: 'Washer' },
  { icon: 'dryer', text: 'Dryer' },
  { icon: 'hair_dryer', text: 'Hair Dryer' },
  { icon: 'iron', text: 'Iron' },
  { icon: 'tv', text: 'Cable TV' },
  { icon: 'tv', text: 'TV' },
  { icon: 'keypad', text: 'Keypad' },
  { icon: 'essentials', text: 'Essentials' },
  { icon: 'first_aid', text: 'First Aid Kit' },
  { icon: 'shampoo', text: 'Shampoo' },
  { icon: 'hangers', text: 'Hangers' },
  { icon: 'smoke_detector', text: 'Smoke Detector' },
  { icon: 'CM_detector', text: 'Carbon Monoxide Detector' },
  { icon: 'fire_extinguisher', text: 'Fire Extinguisher' },
  { icon: 'safety_card', text: 'Safety Card' },
  { icon: 'buzzer', text: 'Buzzer/Wireless Intercom' },
  { icon: 'private_entrance', text: 'Private entrance' },
  { icon: 'private_livingroom', text: 'Private livingroom' },
  { icon: 'locker', text: 'Lock on Bedroom Door' },
  { icon: 'doorman', text: 'Doorman' },
  { icon: 'lockbox', text: 'Lockbox' },
  { icon: 'smartlock', text: 'Smartlock' },
  { icon: 'family_friendly', text: 'Family/Kid Friendly' },
  { icon: 'smoking', text: 'Smoking Allowed' },
  { icon: 'events', text: 'Suitable for Events' },
  { icon: 'checkin', text: '24-Hour Check-in' },
  { icon: 'checkin', text: 'Self Check-in' },
  { icon: 'breakfast', text: 'Breakfast' },

  { icon: 'pets_allowed', text: 'Pets Allowed' },
  { icon: 'pets_live', text: 'Pets live on this property' },
  { icon: 'baby', text: 'Baby Monitor' },
  { icon: 'baby', text: 'Baby bath' },
  { icon: 'baby', text: 'Babysitter recommendations' },
  { icon: 'baby', text: "Pack 'n Play/travel crib" },
  { icon: 'family_friendly', text: "Children's books and toys" },
  { icon: 'family_friendly', text: "Children's dinnerware" },
  { icon: 'bath', text: 'Bathtub' },
  { icon: 'bath', text: 'Tub with shower bench' },
  { icon: 'wheelchair', text: 'Accessible-height bed' },
  { icon: 'wheelchair', text: 'Accessible-height toilet' },
  { icon: 'bed', text: 'Bed linens' },
  { icon: 'bed', text: 'Wide clearance to bed' },
  { icon: 'guard', text: 'Stair gates' },
  { icon: 'guard', text: 'Window guards' },
  { icon: 'guard', text: 'Table corner guards' },
  { icon: 'guard', text: 'Fireplace guards' },
  { icon: 'guard', text: 'Outlet covers' },
  { icon: 'parking', text: 'Disabled parking spot' },
  { icon: 'accessibility', text: 'Wide hallway clearance' },
  { icon: 'accessibility', text: 'Step-free access' },
  { icon: 'accessibility', text: 'Wide doorway' },
  { icon: 'accessibility', text: 'Flat smooth pathway to front door' },
  { icon: 'accessibility', text: 'Path to entrance lit at night' },
  { icon: 'accessibility', text: 'Grab-rails for shower and toilet' },
  { icon: 'accessibility', text: 'Roll-in shower with shower bench or chair' },
  { icon: 'accessibility', text: 'Wide clearance to shower and toilet' },
  { icon: 'table', text: 'Changing table' },
  { icon: 'chair', text: 'High chair' },
  { icon: 'shades', text: 'Room darkening shades' },
  { icon: 'console', text: 'Game console' },
  { icon: 'microwave', text: 'Microwave' },
  { icon: 'coffee', text: 'Coffee maker' },
  { icon: 'refrigerator', text: 'Refrigerator' },
  { icon: 'dishwasher', text: 'Dishwasher' },
  { icon: 'dishes', text: 'Dishes and silverware' },
  { icon: 'kitchen', text: 'Cookware' },
  { icon: 'oven', text: 'Oven' },
  { icon: 'stove', text: 'Stove' },
  { icon: 'bbq', text: 'BBQ grill' },
  { icon: 'patio', text: 'Patio or balcony' },
  { icon: 'garden', text: 'Garden or backyard' },
  { icon: 'beach', text: 'Beach essentials' },
];

allAmenities.forEach((amenity) => {
  amenity.lowerCase = amenity.text.toLowerCase();
});

export default allAmenities;
