import { useLocation, useParams } from 'react-router-dom';
import qs from 'query-string';
import moment from 'moment';

import { DATE_FORMAT } from 'constants/date';
import { ALLOWED_POINT_OF_SALE } from 'constants/constants';

function useSearchValues() {
  const { search } = useLocation();
  const { id } = useParams();
  const {
    checkIn: startDate,
    checkOut: endDate,
    minOccupancy,
    rooms,
    city,
    country,
    currency,
    minPrice,
    maxPrice,
    propertyType,
    tags,
    includeAmenities,
    numberOfBedrooms,
    numberOfBathrooms,
    pointofsale,
    sortBy,
    sortOrder,
  } = qs.parse(search);

  return {
    startDate: startDate && moment(startDate).isValid() ? moment(startDate) : undefined,
    endDate: endDate && moment(endDate).isValid() ? moment(endDate) : undefined,
    checkInDateLocalized: startDate && moment(startDate).isValid() ? moment(startDate).format(DATE_FORMAT) : undefined,
    checkOutDateLocalized: endDate && moment(endDate).isValid() ? moment(endDate).format(DATE_FORMAT) : undefined,
    ...(Number(minOccupancy) && { minOccupancy: Number(minOccupancy) }),
    ...(Number(rooms) && { rooms: Number(rooms) }),
    listingId: id,
    city,
    country,
    currency,
    minPrice,
    maxPrice,
    propertyType,
    tags,
    includeAmenities,
    numberOfBedrooms,
    numberOfBathrooms,
    pointofsale: ALLOWED_POINT_OF_SALE.includes(pointofsale) ? pointofsale : undefined,
    sortBy,
    sortOrder,
  };
}

export default useSearchValues;
