import { find } from 'lodash';

const FARE_ACCOMMODATION_ADJUSTED = 'fareAccommodationAdjusted';
const HOST_PAYOUT = 'hostPayout';
const TOTAL_FEES = 'totalFees';
const TOTAL_TAXES = 'totalTaxes';

export function getSelectedRatePlans(quote, selectedRatePlanId) {
  return quote.map((q) => {
    const {
      rates: { ratePlans },
    } = q;
    return find(ratePlans, (rp) => rp.ratePlan._id === selectedRatePlanId);
  });
}

export function calculateMoney(selectedRatePlans, key) {
  return selectedRatePlans?.reduce((acc, { ratePlan }) => {
    const { money } = ratePlan;
    return acc + money[key];
  }, 0);
}

export function calculateFareAccommodationAdjusted(selectedRatePlans) {
  return calculateMoney(selectedRatePlans, FARE_ACCOMMODATION_ADJUSTED);
}

export function calculateHostPayout(selectedRatePlans) {
  return calculateMoney(selectedRatePlans, HOST_PAYOUT);
}

export function calculateTotalFees(selectedRatePlans) {
  return calculateMoney(selectedRatePlans, TOTAL_FEES);
}

export function calculateTotalTaxes(selectedRatePlans) {
  return calculateMoney(selectedRatePlans, TOTAL_TAXES);
}
