import { useState, useCallback, useEffect, useContext, useRef } from 'react';
import find from 'lodash/find';
import cn from 'classnames';
import { Link, useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';
import min from 'lodash/min';

import createStyles from '@guestyci/foundation/createStyles';
import TextField from '@guestyci/foundation/TextField/TextField';
import Divider from '@guestyci/foundation/Divider';
import Spinner from '@guestyci/foundation/Spinner';
import Dropdown, { Option } from '@guestyci/foundation/Dropdown';
import Icon from '@guestyci/foundation/Icon/Icon';
import { ReactComponent as BtnLeftSmall } from '@guestyci/icons/BtnLeftSmall.svg';
import { Row } from '@guestyci/foundation/Layout';
import t from '@guestyci/localize/t.macro/t.macro';

import useSearchValues from 'hooks/useSearchValues';
import useCreateGetQuote from 'hooks/useCreateGetQuote';
import useDio from 'hooks/useDio';
import useBookNowButtonText from 'hooks/useBookNowButtonText';

import RatePlanRadioGroup from 'components/RatePlanRadioGroup';
import Promotion from 'components/Promotion';
import Coupon from 'components/Coupon';
import Prices from 'components/Prices';
import DateRangePickerAvailability from 'components/DateRangePickerAvailability';
import StepWizard from 'components/StepWizard';
import IconInput from 'components/IconInput';
import { RatePlan } from 'components/RatePlan';
import DatesAndGuests from 'components/DatesAndGuests';
import BookNowError from 'components/BookNowError';
import PriceInfo from 'components/PriceInfo';

import { WebsiteSettingsContext } from 'context/WebsiteSettingsContext';

import { getGuestItems, generateSearchParams, joinUrl } from 'utils';
import { DATE_FORMAT } from 'constants/date';
import { INVOICE_ITEM_TYPE } from 'constants/constants';
import useGetQuote from 'hooks/useGetQuote';
import useGetPathToNavigate from 'hooks/useGetPathToNavigate';

const useStyles = createStyles(({ boxShadow, breakpoints: { create } }) => ({
  root: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    padding: 30,
    background: '#fff',
    boxShadow: boxShadow[1],
    borderRadius: 20,
    alignSelf: 'flex-start',
    width: 394,
    [create('xs')]: {
      width: '100%',
      padding: 10,
    },
    [create('md')]: {
      maxWidth: 394,
      padding: 30,
    },
    [create('xl')]: {
      marginTop: '-30px',
      position: 'sticky',
      top: 10,
      padding: 30,
    },
  },
  dateRangePicker: {
    [create('xs')]: {
      width: '100%',
      '& .DateRangePicker_picker': {
        left: '-75px!important',
      },
    },
  },
  formRoot: {
    width: '100%',
    flexDirection: 'column',
  },
  bookNow: {
    fontSize: 18,
    width: '100%',
    maxWidth: 280,
  },
  buttonWrapper: {
    marginTop: 40,
    justifyContent: 'space-between',
  },
  stepControl: {
    display: 'flex',
  },
  inputDataHeading: {
    fontSize: 14,
  },
  selectedRatePlanInput: {
    marginTop: 10,
  },
  selectedRatePlanDataHeading: {
    fontSize: 14,
  },
  btnBack: {
    maxWidth: 44,
  },
}));

// TODO clean-up state duplications use props instead
const BookNow = ({ property }) => {
  const history = useHistory();
  const { search } = useLocation();
  const {
    minOccupancy: queryGuestsCount,
    listingId: queryListingId,
    startDate: queryStartDate,
    endDate: queryEndDate,
    pointofsale,
  } = useSearchValues();
  const { root, stepControl, selectedRatePlanInput, btnBack, buttonWrapper, bookNow, dateRangePicker } = useStyles();
  const { dioTrack } = useDio();
  const { contentConfiguration: { maxGuests } = null, displayOptions: { shouldShowGuestsFilter } = null } =
    useContext(WebsiteSettingsContext);
  const bookNowButtonText = useBookNowButtonText();
  const maxGuestsOptions = getGuestItems(min([maxGuests, property.accommodates]));
  const maxGuestsNumber = maxGuestsOptions.length;
  const { path } = useGetPathToNavigate();
  // eslint-disable-next-line no-nested-ternary
  const guestsCount = queryGuestsCount ? (queryGuestsCount > maxGuestsNumber ? undefined : queryGuestsCount) : null;
  const [selectedGuestsCount, setSelectedGuestsCount] = useState(guestsCount);
  const [dateRange, setDateRange] = useState({ startDate: queryStartDate, endDate: queryEndDate });
  const [currentStep, setCurrentStep] = useState(0);
  const [ratePlan, setRatePlan] = useState(null);
  const [selectedRatePlan, setSelectedRatePlan] = useState(null);
  const originalRatePlanData = useRef(null);
  const [quoteData, setQuoteData] = useState(null);
  const params = {
    checkInDateLocalized: moment(dateRange.startDate).format(DATE_FORMAT),
    checkOutDateLocalized: moment(dateRange.endDate).format(DATE_FORMAT),
    guestsCount: selectedGuestsCount,
    listingId: queryListingId,
    pointofsale,
  };
  const { DISCOUNT } = INVOICE_ITEM_TYPE;
  const actualDiscount = selectedRatePlan?.ratePlan?.money?.invoiceItems?.find((item) => item.type === DISCOUNT);
  const pushDates = useCallback(
    ({ dates, guests }) => {
      const searchParams = generateSearchParams({ dates, guests });
      history.push({ search: searchParams });
    },
    [history]
  );
  const finishGetQuote = useCallback(() => {
    pushDates({
      dates: dateRange,
      guests: selectedGuestsCount,
    });
  }, [selectedGuestsCount, dateRange, pushDates]);

  const {
    isLoading,
    isSuccess: isCreatingQuoteSuccess,
    isError: isCreatingQuoteError,
    handleCreateQuote,
    data,
  } = useCreateGetQuote({ params, onSuccess: finishGetQuote });

  const {
    isLoading: isGettingQuoteLoading,
    isFetching: isGettingQuoteFetching,
    isSuccess: isGettingQuoteSuccess,
    refetch: refetchQuote,
  } = useGetQuote(data?._id, {
    enabled: !!isCreatingQuoteSuccess,
    onSuccess: setQuoteData,
  });

  const isPageLoading = isGettingQuoteLoading || isLoading || isGettingQuoteFetching;
  const isSubmitQuoteDisabled = (!dateRange.startDate && !dateRange.endDate) || isPageLoading || !selectedGuestsCount;

  const goBack = () => {
    if (quoteData?.rates?.ratePlans?.length > 1) {
      setCurrentStep(1);
    } else {
      setCurrentStep(0);
      setQuoteData(null);
    }
  };

  const resetRatePlanAndQuote = () => {
    setRatePlan(null);
    setSelectedRatePlan(null);
    setCurrentStep(0);
    setQuoteData(null);
  };

  const handleSetGuestsCount = (e) => {
    const { value } = e.target;
    setSelectedGuestsCount(parseInt(value, 10) || 1);
  };

  const handlePlanChange = useCallback((e) => {
    const { value } = e.target;
    setRatePlan(value);
  }, []);

  useEffect(() => {
    if (selectedRatePlan && selectedRatePlan?.quoteId !== originalRatePlanData?.current?.quoteId) {
      originalRatePlanData.current = selectedRatePlan;
    }
  }, [selectedRatePlan]);

  useEffect(() => {
    if (ratePlan) {
      const plans = quoteData?.rates?.ratePlans;
      const _selectedRatePlan = find(plans, { ratePlan: { _id: ratePlan } }) || {};
      setSelectedRatePlan(_selectedRatePlan);
    }
  }, [ratePlan, quoteData]);

  useEffect(() => {
    if (!queryStartDate || !queryEndDate) {
      resetRatePlanAndQuote();
    }
  }, [queryStartDate, queryEndDate]);

  useEffect(() => {
    if (queryStartDate && queryEndDate && !quoteData && shouldShowGuestsFilter && guestsCount) {
      handleCreateQuote();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const ratePlansCount = quoteData?.rates?.ratePlans.length;
    if (currentStep === 2) return;
    if (ratePlansCount === 1) {
      setCurrentStep(2);
      setRatePlan(quoteData?.rates?.ratePlans[0]?.ratePlan?._id);
    } else if (ratePlansCount && ratePlansCount > 1) {
      setCurrentStep(1);
    }
  }, [quoteData, currentStep]);

  useEffect(() => {
    if (isGettingQuoteSuccess) {
      finishGetQuote();
    }
  }, [finishGetQuote, isGettingQuoteSuccess]);

  const handleSearchClick = () => {
    dioTrack('click_book', 'click', {
      listings: [
        {
          guests: selectedGuestsCount,
          listing_id: property._id,
          listing_nickname: property.nickname,
          item_title: property.title,
          currency: property.prices.currency,
          price: property.prices.basePrice,
          quantity: 1,
        },
      ],
    });
  };

  const isSHowSelectedRatePlan = selectedRatePlan?.ratePlan && selectedRatePlan?.ratePlan?.cancellationPolicy;

  return (
    <div data-book-property className={root}>
      <StepWizard activeStep={currentStep}>
        <>
          <div>
            <DateRangePickerAvailability
              dateRange={dateRange}
              setDateRange={setDateRange}
              listingId={queryListingId}
              className={cn('mb-4', dateRangePicker)}
            />

            <BookNowError data={{ isCreatingQuoteError, guestsCount, maxGuestsNumber }} />

            <Dropdown
              placeholder={t('Guests')}
              value={selectedGuestsCount}
              onChange={handleSetGuestsCount}
              className="mb-8"
              input={<IconInput />}
              inputProps={{
                readOnly: true,
              }}
            >
              {maxGuestsOptions?.map((guest) => (
                <Option value={guest.value} key={guest.value}>
                  {guest.label}
                </Option>
              ))}
            </Dropdown>
          </div>
          <div className={stepControl}>
            <button
              className="btn btn-colored btn-full"
              disabled={isSubmitQuoteDisabled}
              type="button"
              onClick={handleCreateQuote}
            >
              {isPageLoading ? <Spinner /> : t('Search')}
            </button>
          </div>
        </>
        <>
          <DateRangePickerAvailability
            dateRange={dateRange}
            setDateRange={(dates) => {
              setDateRange(dates);
              resetRatePlanAndQuote();
            }}
            listingId={queryListingId}
            className="mb-4"
          />

          <Dropdown
            placeholder={t('Guests')}
            value={selectedGuestsCount}
            onChange={(e) => {
              handleSetGuestsCount(e);
              resetRatePlanAndQuote();
            }}
            className="mb-8"
            input={<IconInput />}
            inputProps={{
              readOnly: true,
            }}
          >
            {maxGuestsOptions?.map((guest) => (
              <Option value={guest.value} key={guest.value}>
                {guest.label}
              </Option>
            ))}
          </Dropdown>
          {dateRange?.endDate && dateRange?.startDate && (
            <TextField className="mb-2" bold variant="h4">
              {`${dateRange?.endDate.diff(dateRange?.startDate, 'days')} nights`}
            </TextField>
          )}
          <RatePlanRadioGroup
            handleChange={handlePlanChange}
            ratePlans={quoteData?.rates?.ratePlans}
            selectedPlan={ratePlan}
            defaultCheckInTime={property.defaultCheckInTime}
          />

          <button
            data-qa="rate-plan-submit"
            disabled={!ratePlan}
            type="button"
            className="mt-6 btn btn-colored btn-full"
            onClick={() => setCurrentStep(2)}
          >
            {t('Next')}
          </button>
        </>
        <>
          <TextField variant="h3">{property?.title}</TextField>
          <DatesAndGuests
            startDate={dateRange?.startDate}
            endDate={dateRange?.endDate}
            guestsCount={selectedGuestsCount}
          />
          <Divider />
          <div className={selectedRatePlanInput}>
            {isSHowSelectedRatePlan && (
              <>
                <RatePlan ratePlan={selectedRatePlan?.ratePlan} defaultCheckInTime={property.defaultCheckInTime} />
                <Divider className="mt-1 mb-1" />
              </>
            )}
          </div>
          <Promotion promotion={quoteData?.promotions} />
          <Coupon
            quoteId={quoteData?._id}
            actualDiscount={actualDiscount}
            getUpdatedQuote={refetchQuote}
            hostPayout={selectedRatePlan?.ratePlan?.money?.hostPayout}
          />
          <Prices money={selectedRatePlan?.ratePlan?.money} />
          <PriceInfo ratePlan={selectedRatePlan?.ratePlan} />
          <Row fullWidth className={buttonWrapper}>
            <button
              data-qa="btn-back"
              onClick={goBack}
              type="button"
              aria-label="Back"
              className={cn(btnBack, 'btn btn-sm btn-outline btn-colored btn-no-hover')}
            >
              <Icon svg={BtnLeftSmall} height={12} width={12} />
            </button>
            <Link
              data-qa="request-to-book"
              to={{
                pathname: joinUrl(path, '/checkout'),
                search,
                state: { quoteId: data?._id, ratePlanId: ratePlan, property, selectedRatePlanData: selectedRatePlan },
              }}
              className={cn(bookNow, 'btn btn-colored')}
              onClick={handleSearchClick}
            >
              {bookNowButtonText}
            </Link>
          </Row>
        </>
      </StepWizard>
    </div>
  );
};

export default BookNow;
