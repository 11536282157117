import { useState } from 'react';

import createStyles from '@guestyci/foundation/createStyles';
import Accordion from '@guestyci/foundation/Accordion';
import { Col, Row } from '@guestyci/foundation/Layout';
import TextField from '@guestyci/foundation/TextField/TextField';
import Divider from '@guestyci/foundation/Divider/Divider';
import t from '@guestyci/localize/t.macro/t.macro';

import PriceConverter from 'components/PriceConverter';
import { formatTaxFeeTitle } from 'utils/index';
import { getTaxTypes, getFeeTypes } from 'constants/constants';

const useStyles = createStyles(() => ({
  root: {},
  oldSubTotal: {
    textDecoration: 'line-through',
  },
  totalWrapper: {
    cursor: 'pointer',
    position: 'relative',
  },
  accordionWrapper: {
    width: '100%',
    '& button[class*="Accordion-header"]': {
      width: 70,
    },
    '& div[class*="Accordion-title"]': {
      fontWeight: 'normal',
    },
    '& div[class*="Accordion-content"] > div': {
      padding: '5px 0px',
    },
  },
  totalPriceWrapper: {
    position: 'absolute',
    right: 0,
    top: 10,
  },
  originalPrice: {
    textDecoration: 'line-through',
  },
}));

const CollapseData = ({ totalAmount, globalCurrency, accordionData, title }) => {
  const [showAccordion, setShowAccordion] = useState(false);
  const { totalWrapper, accordionWrapper, totalPriceWrapper } = useStyles();

  return (
    <Row align="baseline" justify="between" className={totalWrapper} onClick={() => setShowAccordion((prev) => !prev)}>
      <Accordion
        className={accordionWrapper}
        title={<TextField color="secondary">{title}</TextField>}
        open={showAccordion}
      >
        {accordionData}
      </Accordion>
      <TextField className={totalPriceWrapper}>
        <PriceConverter amount={totalAmount} currency={globalCurrency} />
      </TextField>
    </Row>
  );
};

const CollapseDataRow = ({ title, amount, currency }) => (
  <Row data-qa="collapse-data-row" justify="between" fullWidth>
    <TextField color="secondary">{title}</TextField>
    <TextField color="secondary">
      <PriceConverter amount={amount} currency={currency} />
    </TextField>
  </Row>
);

export const Taxes = ({ invoiceItems }) => {
  const translatedTaxTypes = getTaxTypes();
  const globalCurrency = invoiceItems[0].currency;
  let totalAmount = 0;
  const taxes = invoiceItems
    .filter((tax) => tax.type === 'TAX')
    .map((tax) => {
      const { title, amount, currency, normalType, type } = tax;
      const translatedLabel = translatedTaxTypes[type]?.label;
      totalAmount += amount;
      const formattedTitle = formatTaxFeeTitle({ title, normalType, translatedLabel });
      return <CollapseDataRow title={formattedTitle} amount={amount} currency={currency} />;
    });

  return (
    <CollapseData title={t('Taxes')} totalAmount={totalAmount} globalCurrency={globalCurrency} accordionData={taxes} />
  );
};

export const Fees = ({ invoiceItems }) => {
  const translatedFeeTypes = getFeeTypes();
  let totalAmount = 0;
  const globalCurrency = invoiceItems[0].currency;
  // TODO: map over all fees
  const fees = invoiceItems
    .filter((tax) => tax.type === 'ADDITIONAL' || tax.type === 'CLEANING_FEE')
    .map((fee) => {
      const { title, amount, currency, normalType, type } = fee;
      const translatedLabel = translatedFeeTypes[type]?.label;
      totalAmount += amount;
      const formattedTitle = formatTaxFeeTitle({ title, normalType, translatedLabel });
      return <CollapseDataRow key={formattedTitle} title={formattedTitle} amount={amount} currency={currency} />;
    });

  return (
    !!totalAmount && (
      <CollapseData title={t('Fees')} totalAmount={totalAmount} globalCurrency={globalCurrency} accordionData={fees} />
    )
  );
};

const Prices = ({ money = null }) => {
  const { originalPrice } = useStyles();
  if (!money) {
    return null;
  }

  const {
    currency,
    invoiceItems,
    fareAccommodation,
    fareAccommodationAdjusted,
    subTotalPrice,
    hostPayout,
    totalTaxes,
  } = money;

  return (
    <Col>
      <Row className="pb-2" justify="between" align="end">
        <TextField bold>{t('Subtotal')}</TextField>
        <Col align="end">
          {fareAccommodation !== fareAccommodationAdjusted && (
            <TextField data-qa="subtotal-price" className={originalPrice} color="light">
              <PriceConverter amount={fareAccommodation} currency={currency} />
            </TextField>
          )}
          <TextField bold>
            <PriceConverter amount={fareAccommodationAdjusted} currency={currency} />
          </TextField>
        </Col>
      </Row>
      <Divider />
      <Fees invoiceItems={invoiceItems} />
      {totalTaxes > 0 && (
        <div data-qa="taxes">
          <Row className="pb-2 mt-2" justify="between" align="end">
            <TextField bold>{t('Subtotal before taxes')}</TextField>
            <Col align="center">
              <TextField bold>
                <PriceConverter amount={subTotalPrice} currency={currency} />
              </TextField>
            </Col>
          </Row>
          <Divider />
          <Taxes invoiceItems={invoiceItems} />
          <Divider />
        </div>
      )}
      <Row className="pb-2 pt-2" justify="between" align="end">
        <TextField bold variant="h3">
          {t('Total')}
        </TextField>
        <Col align="center">
          <TextField data-qa="total-price" bold variant="h3">
            <PriceConverter amount={hostPayout} currency={currency} />
          </TextField>
        </Col>
      </Row>
    </Col>
  );
};

export default Prices;
