import { useContext } from 'react';

import { GuestyPayWidget } from '@guestyci/guesty-pay-widget';
import useFeatureToggle from '@guestyci/feature-toggle-fe/useFeatureToggle';
import { white } from '@guestyci/foundation/theme/colors';

import { WebsiteSettingsContext } from 'context/WebsiteSettingsContext';
import PaymentMethodSwitch from 'components/PaymentMethodSwitch';
import BankTransferInformation from 'components/BankTransferInformation';
import { BANK_TRANSFER } from 'constants/featureToggleNames';

const GuestyPayInstantForm = ({ providerId, currency, hostPayout }) => {
  const {
    paymentTypes: {
      creditCard: creditCardPaymentTypeEnabled = true,
      bankTransfer: bankTransferPaymentTypeEnabled = false,
    } = {},
  } = useContext(WebsiteSettingsContext);
  const [, isBankTransferEnabled] = useFeatureToggle(BANK_TRANSFER);

  const creditCardForm = (
    <GuestyPayWidget
      providerId={providerId}
      currency={currency}
      amount={hostPayout}
      styles={{ colorBackground: white }}
    />
  );

  if (isBankTransferEnabled && (creditCardPaymentTypeEnabled || bankTransferPaymentTypeEnabled)) {
    return (
      <PaymentMethodSwitch
        isCreditCardEnabled={creditCardPaymentTypeEnabled}
        isBankTransferEnabled={bankTransferPaymentTypeEnabled}
        creditCardForm={creditCardForm}
        BankTransferInformation={<BankTransferInformation />}
      />
    );
  }

  return creditCardForm;
};

export default GuestyPayInstantForm;
