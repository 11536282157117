import { useEffect, useContext } from 'react';
import { ReactSession } from 'react-client-session';
import uniqid from 'uniqid';

import Header from 'components/Header';
import Footer from 'components/Footer';
import RootStylesWrapper from 'components/RootStylesWrapper';

import useGoogleAnalytics from 'hooks/useGoogleAnalytics';
import useDio from 'hooks/useDio';
import useGoogleTagManager from 'hooks/useGoogleTagManager';
import { WebsiteSettingsContext } from 'context/WebsiteSettingsContext';
import useFacebookPixel from 'hooks/useFacebookPixel';
import { CookiePolicyStrategy } from '../components/CookiePolicy/CookiePolicy';
import AppRoutes from './AppRoutes';

const initSession = () => {
  ReactSession.setStoreType('sessionStorage');
  const userSession = ReactSession.get('user_session');
  if (!userSession) {
    ReactSession.set('user_session', uniqid());
  }

  const userId = localStorage.getItem('user_id');
  if (!userId) {
    localStorage.setItem('user_id', uniqid());
  }
};

const App = () => {
  const {
    analytics: { googleAnalytics: GA = null, googleTag = null, facebookPixel = null } = {},
    cookiesPolicyAccepted,
  } = useContext(WebsiteSettingsContext);
  const { initAnalytics } = useGoogleAnalytics(GA);
  const { initGoogleTagManager } = useGoogleTagManager(googleTag);
  const { initDio, dioInited } = useDio();
  const { initAnalytics: initFBPixel } = useFacebookPixel({
    facebookPixelCode: facebookPixel,
    isAnalyticsAvailable: cookiesPolicyAccepted,
  });

  useEffect(() => {
    initDio();
    initSession();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    initAnalytics();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [GA]);
  useEffect(() => {
    initGoogleTagManager();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [googleTag]);
  useEffect(() => {
    initFBPixel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facebookPixel]);

  if (!dioInited) return null;

  return (
    <RootStylesWrapper>
      <Header />
      <AppRoutes />
      <CookiePolicyStrategy isVisible={!!facebookPixel} />
      <Footer />
      {/*
        Safari fix.
        It is probably has to do with tabindex; like it looks like Safari doesn't like when you close the popup and the browser doesn't have any other input / "tabindexable" element to focus.
        Explanation: https://github.com/algolia/docsearch/issues/1260#issuecomment-1011939736
      */}
      <div style={{ position: 'fixed', zIndex: -1, opacity: 0 }}>
        <input type="text" />
      </div>
    </RootStylesWrapper>
  );
};

export default App;
